import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf";
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";
import fontRobotoMedium from "../../assets/fonts/Roboto/Roboto-Medium.ttf";
import fontRobotoItalic from "../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";
import { API_BASE_URL } from "../../config";
import { useState, useEffect } from "react";
import axios from "axios";
import useIsMountedRef from "../mountedRef";
import moment from "moment/moment";

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoMedium", src: fontRobotoMedium });
Font.register({ family: "RobotoBold", src: fontRobotoBold });
Font.register({ family: "RobotoItalic", src: fontRobotoItalic });
Font.registerHyphenationCallback((word) => [word]);

let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
  month = "0" + month;
}

if (day < 10) {
  day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`;

const styles = StyleSheet.create({
  fontRegular: { fontFamily: "RobotoRegular" },
  fontLight: { fontFamily: "RobotoLight" },
  fontBold: { fontFamily: "RobotoBold" },
  fontMedium: { fontFamily: "RobotoMedium" },
  blockDebours: {
    width: 225,
    maxHeight: 25,
    marginTop: 20,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "row",
    border: "1px solid grey",
    borderRadius: 3,
    paddingBottom: 24,
  },
  blockEmoluments: {
    width: 225,
    marginLeft: 0,
    marginRight: 0,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 60,
    height: "auto",
    maxHeight: 130,
  },
  blockFiscalite: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 80,
  },
  blockFiscalite2: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 100,
  },
  blockLeft: {
    position: "relative",
    marginLeft: "10%",
    marginRight: 0,
    width: "100%",
  },
  blockRight: {
    position: "relative",
    marginLeft: 0,
    marginRight: "5%",
    width: "100%",
  },
  bodyCtnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "space-between",
    marginTop: 15,
    width: "100%",
  },
  bodyFooterMedium: {
    fontFamily: "RobotoMedium",
    fontSize: 8,
    marginTop: 10,
    textAlign: "justify",
  },
  bodyFooterCtn: {
    width: "80%",
    marginLeft: "10%",
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    objectPosition: "bottom",
  },
  bodyFooterLight: {
    fontFamily: "RobotoLight",
    fontSize: 6,
    marginTop: 5,
  },
  bodyFooterMapped: {
    fontSize: 8,
    marginTop: 4,
  },
  bodyFooterMappedItalic: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoItalic",
  },
  bodyFooterMappedTitle: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoBold",
  },
  containerTitleSimu: {
    position: "relative",
    marginTop: 20,
    marginRight: "auto",
    marginLeft: "auto",
    width: "75%",
    maxHeight: 50,
    overflow: "hidden",
  },
  ctnChart: {
    position: "absolute",
    bottom: 220,
    width: 170,
    height: 180,
    left: "35%",
  },
  ctnEmol: {
    width: 150,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 5,
  },
  ctnResultatsNb: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    textAlign: "right",
  },
  ctnResultatsNb2: {
    width: 75,
    fontFamily: "RobotoBold",
    fontSize: 12,
    textAlign: "right",
  },
  ctnResultatsNb3: {
    width: "60%",
    height: 20,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 1,
    textAlign: "right",
  },
  ctnResultatsNbTop: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    borderBottom: "1px solid grey",
    textAlign: "right",
  },
  ctnResultatsTxt: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyItems: "right",
    marginBottom: 25,
  },
  ctnResultatsTxt2: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    justifyContent: "space-between",
  },
  debours: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(214,127,98,0.71)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  dmtgResults: {
    fontWeight: "normal",
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  donataireBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "5px",
    marginBottom: "15px",
    width: "100%",
    fontFamily: "RobotoBold",
  },
  donataireTitle: {
    width: "30%",
    maxWidth: 90,
    fontSize: 13,
    color: "white",
    backgroundColor: "#9d72a9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 40,
  },
  donateurBlock: {
    border: "1px solid #71add9",
    borderRadius: 3,
    margin: 10,
    maxWidth: 180,
    minWidth: 150,
    boxSizing: "border-box",
  },
  donateurResults: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  donateurTitle: {
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: "#71add9",
    fontSize: 12,
    padding: 10,
  },
  emoluments: {
    width: "70%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(164,148,128,0.8)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  globalBlock: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    marginBottom: "15px",
    border: "1px solid #9d72a9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
  },
  headerAdress: {
    fontSize: 10,
    fontFamily: "RobotoRegular",
  },
  headerDate: {
    marginTop: 10,
    fontSize: 9.5,
    fontFamily: "RobotoRegular",
  },
  headerName: {
    fontSize: 11,
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  headerRight: {
    marginLeft: "50%",
    marginTop: 5,
    marginRight: 40,
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1.5,
    wordBreak: "keep-all",
  },
  heritierBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 5,
    marginBottom: 15,
    border: "1px solid #71ADD9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    height: "auto",
  },
  heritierBlock2: {
    flex: 1,
    margin: 10,
    maxWidth: "100%",
  },
  heritierTitle: {
    height: "100%",
    width: "auto",
    fontSize: 13,
    color: "white",
    backgroundColor: "#71ADD9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
  },
  page: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  rappel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    columnGap: 50,
    width: "50%",
    height: 150,
    marginLeft: "10%",
    marginRight: "10%",
    position: "absolute",
    bottom: 60,
  },
  rappelBlockDonataire: {
    marginTop: 5,
    marginBottom: 10,
  },
  rappelDMTG: {
    position: "relative",
    width: "80%",
    marginLeft: "10%",
    // marginTop: 175,
    marginTop: 30,
    marginBottom: 50,
    paddingBottom: 150,
  },
  rappelDonateurTitle: {
    fontSize: 10,
    marginTop: 5,
    fontWeight: "bold",
    color: "rgb(157 114 169)",
  },
  rappelDonataireTitle: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#71ADD9",
  },
  remarque: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoRegular",
    fontSize: 10,
    marginTop: 15,
    lineHeight: 2,
    maxHeight: 40,
    overflow: "hidden",
  },
  remarqueText: {
    marginTop: 8,
    marginLeft: 5,
  },
  resultatsNb: {
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultatsNb2: {
    fontSize: 12,
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultsBlock: {
    marginTop: 5,
    marginBottom: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  resultsTitle: {
    fontSize: 14,
    paddingLeft: "10%",
    width: "100%",
    marginBottom: 10,
    marginTop: 15,
  },
  resultatsTxt: {
    width: 100,
    height: 25,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 5,
  },
  resultatsTxt2: {
    width: 170,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  resultatsTxt3: {
    width: 100,
    height: 20,
    fontFamily: "RobotoLight",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  superEmol: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  taxes: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(71,143,154,0.73)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  titleSimu: {
    fontSize: 13,
    textAlign: "center",
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  ttc: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    fontSize: 14,
    marginTop: 10,
    height: 45,
  },
  ttcInternCtn: {
    flex: 1,
    flexDirection: "row",
    marginTop: 15,
  },
  ttcLeft: {
    paddingLeft: 5,
    width: "70%",
  },
  ttcRight: {
    paddingRight: 5,
    width: "30%",
    textAlign: "center",
  },
});

const GeneratePdfFile = ({ simulateurObject, TblArticleValue }) => {
  let remarque = localStorage.getItem("remarque");

  let currUser = JSON.parse(localStorage.getItem("user"));

  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  let nameSimu = localStorage.getItem("nameSimu");

  let result;
  if (simulateurObject["results"]["custom"].length > 0) {
    result = simulateurObject["results"]["custom"];
  } else {
    result = simulateurObject["results"]["output"];
  }

  let servitudes = [34, 35];

  let valeurSPF = [81, 82];

  let simuPret = [84, 85, 86, 87, 88, 90, 91, 92, 93, 94, 97];

  let simuDonation = [48, 49];

  let simuSuccession = [55];

  let simuDependances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 16, 17];

  let dmtgInputs = null;

  const isMountedRef = useIsMountedRef();
  const [DepsArticles, setDepsArticles] = useState(null);
  const [setLoading] = useState(true);

  let SimulateurID = requestSimu.simulateurid;

  useEffect(() => {
    const fetchData = async () => {
      try {
        var articlesRequest = {
          method: "get",
          url: `${API_BASE_URL}/articles`,
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
          },
        };

        const response = await axios(articlesRequest);

        const depsArticle = [];

        if (simulateurObject?.data?.dependances?.length > 0) {
          for (let i = 0; i < simulateurObject.data.dependances.length; i++) {
            const foundArticle = response.data.find(
              (article) => article.SimulateurID === simulateurObject.data.dependances[i].simulateurid
            );
  
            if (foundArticle) {
              depsArticle.push(foundArticle.TblArticle);
            } else {
              console.warn(
                `Aucun article trouvé : ${simulateurObject.data.dependances[i].simulateurid}`
              );
              depsArticle.push(null);
            }
          }
          setDepsArticles(depsArticle);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des articles :", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMountedRef.current = false;
    };
  }, [SimulateurID, isMountedRef]);

  if (requestSimu?.DMTG?.length > 0) {
    dmtgInputs = JSON.parse(localStorage.getItem("dmtgInputs"));
  }

  let persoEmol = JSON.parse(localStorage.getItem("persoEmol"));

  let simu84Index = null;
  let simu85Index = null;
  let simu89Index = null;
  let simu90Index = null;
  let simu92Index = null;
  let simu96Index = null;
  let simu97Index = null;

  if (simulateurObject?.data?.dependances?.length > 0) {
    for (let i = 0; i < simulateurObject.data.dependances.length; i++) {
      if (simulateurObject.data.dependances[i].simulateurid === 84) simu84Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 85) simu85Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 89) simu89Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 90) simu90Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 92) simu92Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 96) simu96Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 97) simu97Index = i;
    }
    if (DepsArticles == null) {
      return null;
    }
  }

  function checkAndTransformNullToZero(field) {
    if (field !== null && field !== undefined) return field;
    else return 0;
  }

  const RenderChart = () => (
    <Image
      source={{ uri: localStorage.getItem("chart") }}
      style={{
        width: "100%",
        // height: 460
      }}
    />
  );
  const renderDroitDePartage = () => {
    if (
      requestSimu.simulateurid === 79 ||
      requestSimu.simulateurid === 59 ||
      requestSimu.simulateurid === 22 ||
      requestSimu.simulateurid === 61 ||
      requestSimu.simulateurid === 23 ||
      (requestSimu.simulateurid === 60 && simulateurObject?.data?.inputs?.droit_partage_reduit === 0)
    ) {
      return (
        <View
          style={{
            flex: 0.6,
            paddingRight: 4,
            borderRight: "1px solid #eeeae5",
            height: 18,
          }}
        >
          <Text
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dont droit de partage (2,50%)
          </Text>
        </View>
      );
    } else if (
      requestSimu.simulateurid === 58 ||
      (requestSimu.simulateurid === 60 && simulateurObject?.data?.inputs?.droit_partage_reduit === 1)
    ) {
      return (
        <View
          style={{
            flex: 0.6,
            paddingRight: 4,
            borderRight: "1px solid #eeeae5",
            height: 18,
          }}
        >
          <Text
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dont droit de partage (1,10%)
          </Text>
        </View>
      );
    }
  };

  const renderDMTO = () => {
    if (requestSimu.departementid === 36 || requestSimu.departementid === 56) {
      return (
        <View
          style={{
            flex: 0.6,
            paddingRight: 4,
            borderRight: "1px solid #eeeae5",
            height: 18,
          }}
        >
          <Text
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dont DMTO (5,09006%)
          </Text>
        </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 0.6,
            paddingRight: 4,
            borderRight: "1px solid #eeeae5",
            height: 18,
          }}
        >
          <Text
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dont DMTO (5,80665%)
          </Text>
        </View>
      );
    }
  };

  const formatMontant = (value) => {
    return parseInt(value).toLocaleString("en-EN").replace(/,/g, " ") + " €";
  };

  // csi publication servitudes
  const getCsiPublicationValues = (result) => {
    const csiPublicationValues = [];
    const csiPublicationOccurrence = result.find((item) => item.key.startsWith("csi_publication_"));

    if (csiPublicationOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `csi_publication_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          csiPublicationValues.push(object.value);
        }
      }
    } else {
      const csiPublication = result.find((item) => item.key === "csi_publication");
      if (csiPublication) {
        csiPublicationValues.push(csiPublication.value);
      }
    }

    return csiPublicationValues;
  };

  const csiPublicationValues = getCsiPublicationValues(result);

  // emol acte servitudes
  const getEmolActeValues = (result) => {
    const emolActeValues = [];
    const emolActeOccurrence = result.find((item) => item.key.startsWith("emoluments_actes_"));

    if (emolActeOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `emoluments_actes_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          emolActeValues.push(object.value);
        }
      }
    } else {
      const emolActe = result.find((item) => item.key === "emoluments_actes");
      if (emolActe) {
        emolActeValues.push(emolActe.value);
      }
    }

    return emolActeValues;
  };

  const emolActeValues = getEmolActeValues(result);

  // dmto servitudes
  const getDmtoValues = (result) => {
    const dmtoValues = [];
    const dmtoOccurrence = result.find((item) => item.key.startsWith("droit_mutation_"));

    if (dmtoOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `droit_mutation_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          dmtoValues.push(object.value);
        }
      }
    } else {
      const dmto = result.find((item) => item.key === "droit_mutation");
      if (dmto) {
        dmtoValues.push(dmto.value);
      }
    }

    return dmtoValues;
  };

  const dmtoValues = getDmtoValues(result);

  // base trésor servitudes
  const getBaseTresorValues = (result) => {
    const baseTresorValues = [];
    const baseTresorOccurrence = result.find((item) => item.key.startsWith("base_tresor_"));

    if (baseTresorOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `base_tresor_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          baseTresorValues.push(object.value);
        }
      }
    } else {
      const baseTresor = result.find((item) => item.key === "base_tresor");
      if (baseTresor) {
        baseTresorValues.push(baseTresor.value);
      }
    }

    return baseTresorValues;
  };

  const baseTresorValues = getBaseTresorValues(result);

  // base CSI SPF
  const getBaseCsiValues = (result) => {
    const baseCsiValues = [];
    const baseCsiOccurrence = result.find((item) => item.key.startsWith("base_csi_publication_"));

    if (baseCsiOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `base_csi_publication_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          baseCsiValues.push(object.value);
        }
      }
    } else {
      const baseCsi = result.find((item) => item.key === "base_csi_publication");
      if (baseCsi) {
        baseCsiValues.push(baseCsi.value);
      }
    }

    return baseCsiValues;
  };

  const baseCsiValues = getBaseCsiValues(result);

  // base Emol servitudes
  const getBaseEmolValues = (result) => {
    const baseEmolValues = [];
    const baseEmolOccurrence = result.find((item) => item.key.startsWith("base_emol_"));

    if (baseEmolOccurrence) {
      for (let i = 1; i <= 10; i++) {
        const key = `base_emol_${i}`;
        const object = result.find((item) => item.key === key);

        if (object) {
          baseEmolValues.push(object.value);
        }
      }
    } else {
      const baseEmol = result.find((item) => item.key === "base_emol");
      if (baseEmol) {
        baseEmolValues.push(baseEmol.value);
      }
    }

    return baseEmolValues;
  };

  const baseEmolValues = getBaseEmolValues(result);

  const tresorTotalValue = result.find((object) => object.key === "Tresor_total")?.value;
  const droitEtatValue = result.find(
    (object) =>
      object.key === "droit_etat" || object.key === "droit_etat_acte_separe" || object.key === "droit_etat_promesse"
  )?.value;

  const emol1Value = result.find((object) => object.key === "emoluments_actes_1")?.value;
  const emol2Value = result.find((object) => object.key === "emoluments_actes_2")?.value;

  // on cherche les droits états
  const hasDroitEtat = simulateurObject?.results?.output.some(
    (item) => item.key === "droit_etat" && item.value !== "0 €"
  );
  const hasDroitEtatPromesse = simulateurObject?.results?.output.some(
    (item) => item.key === "droit_etat_promesse" && item.value !== "0 €"
  );
  const hasDroitEtatActeSepareDependances = simulateurObject?.data?.dependances?.some(
    (dependance) =>
      dependance.output?.hasOwnProperty("droit_etat_acte_separe") && dependance.output.droit_etat_acte_separe !== "0 €"
  );

  // On vérifie s'il s'agit d'une dépendance
  function isDependanceValid(simuIndex) {
    const dependance = simulateurObject?.data?.dependances?.[simuIndex] ?? null;
    return dependance !== null && dependance !== undefined;
  }

  // On récupère et on formatte les variables
  function formatOutputBaseEmolValue(simuIndex) {
    const baseEmolValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.base_emol ?? 0;
    const formattedValue = parseInt(baseEmolValue?.toFixed(2)).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }
  const formattedBaseEmolValue84 = formatOutputBaseEmolValue(simu84Index);
  const formattedBaseEmolValue85 = formatOutputBaseEmolValue(simu85Index);
  const formattedBaseEmolValue89 = formatOutputBaseEmolValue(simu89Index);
  const formattedBaseEmolValue90 = formatOutputBaseEmolValue(simu90Index);
  const formattedBaseEmolValue92 = formatOutputBaseEmolValue(simu92Index);
  const formattedBaseEmolValue96 = formatOutputBaseEmolValue(simu96Index);
  const formattedBaseEmolValue97 = formatOutputBaseEmolValue(simu97Index);

  function formatOutputEmolValue(simuIndex) {
    const emolValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.emoluments_actes_total ?? 0;
    const formattedValue = emolValue?.toFixed(2).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }
  const formattedEmolValue84 = formatOutputEmolValue(simu84Index);
  const formattedEmolValue85 = formatOutputEmolValue(simu85Index);
  const formattedEmolValue89 = formatOutputEmolValue(simu89Index);
  const formattedEmolValue90 = formatOutputEmolValue(simu90Index);
  const formattedEmolValue92 = formatOutputEmolValue(simu92Index);
  const formattedEmolValue96 = formatOutputEmolValue(simu96Index);
  const formattedEmolValue97 = formatOutputEmolValue(simu97Index);

  function formatOutputEmolFormValue(simuIndex) {
    const emolFormValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.emoluments_formalites_total;
    const formattedValue = emolFormValue?.toFixed(2).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }
  const formattedEmolFormValue84 = formatOutputEmolFormValue(simu84Index);
  const formattedEmolFormValue85 = formatOutputEmolFormValue(simu85Index);
  const formattedEmolFormValue89 = formatOutputEmolFormValue(simu89Index);
  const formattedEmolFormValue90 = formatOutputEmolFormValue(simu90Index);
  const formattedEmolFormValue92 = formatOutputEmolFormValue(simu92Index);
  const formattedEmolFormValue96 = formatOutputEmolFormValue(simu96Index);
  const formattedEmolFormValue97 = formatOutputEmolFormValue(simu97Index);

  function formatOutputTresorTotal(simuIndex) {
    const tresorTotalValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.Tresor_total ?? 0;
    return `${tresorTotalValue?.toFixed(2).toLocaleString("en-EN").replace(/,/g, " ")} €`;
  }

  const formattedTresorTotal84 = formatOutputTresorTotal(simu84Index);
  const formattedTresorTotal85 = formatOutputTresorTotal(simu85Index);
  const formattedTresorTotal90 = formatOutputTresorTotal(simu90Index);
  const formattedTresorTotal92 = formatOutputTresorTotal(simu92Index);
  const formattedTresorTotal97 = formatOutputTresorTotal(simu97Index);

  function formatOutputBaseTpf(simuIndex) {
    const baseTpfValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.base_tpf ?? 0;
    const formattedValue = parseInt(baseTpfValue?.toFixed(2)).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }

  const formattedBaseTpf84 = formatOutputBaseTpf(simu84Index);
  const formattedBaseTpf85 = formatOutputBaseTpf(simu85Index);
  const formattedBaseTpf92 = formatOutputBaseTpf(simu92Index);

  function formatOutputBaseTresor(simuIndex) {
    const baseTresorValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.base_tresor ?? 0;
    const formattedValue = parseInt(baseTresorValue?.toFixed(2)).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }

  const formattedBaseTresor90 = formatOutputBaseTresor(simu90Index);
  const formattedBaseTresor97 = formatOutputBaseTresor(simu97Index);

  function formatOutputTpfValue(simuIndex) {
    const tpfValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.tpf ?? 0;
    return `${tpfValue?.toFixed(2).toLocaleString("en-EN").replace(/,/g, " ")} €`;
  }

  const formattedTpfValue84 = formatOutputTpfValue(simu84Index);
  const formattedTpfValue85 = formatOutputTpfValue(simu85Index);
  const formattedTpfValue92 = formatOutputTpfValue(simu92Index);
  const formattedTpfValue90 = formatOutputTpfValue(simu90Index);
  const formattedTpfValue97 = formatOutputTpfValue(simu97Index);

  function formatOutputBaseCsiInscription(simuIndex) {
    const baseCsiInscriptionValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.base_csi_inscription ?? 0;
    const formattedValue = parseInt(baseCsiInscriptionValue?.toFixed(2)).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }

  const formattedBaseCsiInscription84 = formatOutputBaseCsiInscription(simu84Index);
  const formattedBaseCsiInscription85 = formatOutputBaseCsiInscription(simu85Index);
  const formattedBaseCsiInscription92 = formatOutputBaseCsiInscription(simu92Index);

  function formatOutputCsiInscriptionValue(simuIndex) {
    const csiInscriptionValueValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.csi_inscription ?? 0;
    return `${csiInscriptionValueValue?.toFixed(2).toLocaleString("en-EN").replace(/,/g, " ")} €`;
  }

  const formattedCsiInscriptionValue84 = formatOutputCsiInscriptionValue(simu84Index);
  const formattedCsiInscriptionValue85 = formatOutputCsiInscriptionValue(simu85Index);
  const formattedCsiInscriptionValue92 = formatOutputCsiInscriptionValue(simu92Index);
  const formattedCsiInscriptionValue90 = formatOutputCsiInscriptionValue(simu90Index);
  const formattedCsiInscriptionValue97 = formatOutputCsiInscriptionValue(simu97Index);

  function formatOutputDroitEtat(simuIndex) {
    const droitEtatValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.droit_etat_acte_separe ?? 0;
    return `${droitEtatValue} €`;
  }

  const formattedDroitEtat84 = formatOutputDroitEtat(simu84Index);
  const formattedDroitEtat85 = formatOutputDroitEtat(simu85Index);
  const formattedDroitEtat89 = formatOutputDroitEtat(simu89Index);
  const formattedDroitEtat92 = formatOutputDroitEtat(simu92Index);
  const formattedDroitEtat90 = formatOutputDroitEtat(simu90Index);
  const formattedDroitEtat96 = formatOutputDroitEtat(simu96Index);
  const formattedDroitEtat97 = formatOutputDroitEtat(simu97Index);

  function formatOutputDeboursValue(simuIndex) {
    const DeboursValue = simulateurObject?.data?.dependances?.[simuIndex]?.output?.Debours_total;
    const formattedValue = parseInt(DeboursValue?.toFixed(2)).toLocaleString("en-EN").replace(/,/g, " ");
    return `${formattedValue} €`;
  }
  const formattedDeboursValue84 = formatOutputDeboursValue(simu84Index);
  const formattedDeboursValue85 = formatOutputDeboursValue(simu85Index);
  const formattedDeboursValue89 = formatOutputDeboursValue(simu89Index);
  const formattedDeboursValue90 = formatOutputDeboursValue(simu90Index);
  const formattedDeboursValue92 = formatOutputDeboursValue(simu92Index);
  const formattedDeboursValue96 = formatOutputDeboursValue(simu96Index);
  const formattedDeboursValue97 = formatOutputDeboursValue(simu97Index);

  return (
    // <PDFViewer style={{width: "98vw", height: "98vh"}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerRight}>
          <Text style={styles.headerName}>{currUser.societe}</Text>
          <Text style={styles.headerAdress}>{currUser.societe_address}</Text>

          <Text style={styles.headerAdress}>{currUser.societe_zip + " " + currUser.societe_town}</Text>

          <Text style={styles.headerDate}>Dossier suivi par : {currUser.firstname + " " + currUser.lastname}</Text>

          <View style={styles.headerDate}>
            <Text>Le : {currentDate}</Text>
          </View>
        </View>
        <View style={styles.containerTitleSimu}>
          <Text style={styles.titleSimu}>
            {requestSimu?.simulateurid === 22
              ? "Licitation maintenant l'indivision, régime de FAVEUR - droit de partage à 2,50%. Indivision issue d'une succession, entre époux mariés sous le régime de la séparation de biens, entre partenaires PACSES"
              : requestSimu?.simulateurid === 23
              ? "Licitation faisant cesser l'indivision, régime de FAVEUR - droit de partage à 2,50%. Indivision issue d'une succession, entre époux mariés sous le régime de la séparation de biens, entre partenaires PACSES"
              : nameSimu}
          </Text>
        </View>
        <View style={styles.remarque}>
          <Text style={styles.remarqueText}>Remarques : {remarque}</Text>
        </View>

        <View style={styles.ttc}>
          <View style={styles.ttcInternCtn}>
            <Text style={styles.ttcLeft}>Estimation du montant total de vos frais :</Text>

            <Text style={styles.ttcRight}>
              {(() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "Provision_total") {
                    const numericValue = parseFloat(result[i].value);
                    const roundedValue = Math.ceil(numericValue);
                    const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                    return (formattedValue + " €").toString();
                  }
                }
                return "";
              })()}
            </Text>
          </View>
        </View>

        <View style={styles.bodyCtnRow}>
          <View style={styles.blockLeft}>
            <View style={styles.blockEmoluments}>
              <View style={styles.ctnResultatsTxt}>
                {requestSimu.simulateurid === 32 || requestSimu.simulateurid === 83 ? (
                  <Text style={styles.emoluments}>Honoraires HT</Text>
                ) : (
                  <Text style={styles.emoluments}>Total Emoluments</Text>
                )}
                <View style={styles.ctnResultatsNbTop}>
                  <Text style={styles.resultatsNb}>
                    {(() => {
                      for (let i = 0; i < result.length; i++) {
                        if (result[i].key === "Emol_total") {
                          const numericValue = parseFloat(result[i].value);
                          const roundedValue = Math.ceil(numericValue);
                          const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                          return (formattedValue + " € HT").toString();
                        }
                      }
                      return "";
                    })()}
                  </Text>
                </View>
              </View>

              <View style={styles.ctnResultatsTxt2}>
                {requestSimu.hasOwnProperty("dependances") &&
                requestSimu.simulateurid !== 34 &&
                requestSimu.simulateurid !== 35 &&
                requestSimu.simulateurid !== 81 &&
                requestSimu.simulateurid !== 82 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Vente</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emoluments_actes") {
                              const numericValue = parseFloat(result[i].value);
                              const roundedValue = Math.ceil(numericValue);
                              const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                ) : requestSimu.simulateurid === 32 || requestSimu.simulateurid === 83 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Honoraires HT</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Honoraires") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Emoluments d'acte</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emoluments_actes_total") {
                              const numericValue = parseFloat(result[i].value);
                              const roundedValue = Math.ceil(numericValue);
                              const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                )}

                {requestSimu.simulateurid === 81 || requestSimu.simulateurid === 82 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Honoraires</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          const montant_honoraires =
                            parseInt(requestSimu?.inputs?.montant_honoraires.toFixed(2))
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ")
                              .toString() + " € HT";

                          return montant_honoraires;
                        })()}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {requestSimu.hasOwnProperty("dependances")
                  ? requestSimu?.dependances.map((dependance, index) => {
                      if (
                        dependance.simulateurid === 84 ||
                        dependance.simulateurid === 85 ||
                        dependance.simulateurid === 92
                      ) {
                        return (
                          <View style={styles.superEmol}>
                            <Text style={styles.ctnEmol}>Prêt</Text>
                            <View style={styles.ctnResultatsNb2}>
                              <Text style={styles.resultatsNb2}>
                                {(() => {
                                  const emolumentsActesTotal = parseFloat(
                                    requestSimu?.dependances[index]?.output?.emoluments_actes_total
                                  );
                                  const formattedValue =
                                    Math.ceil(emolumentsActesTotal).toLocaleString("en-EN").replace(/,/g, " ") +
                                    " € HT";
                                  return formattedValue;
                                })()}
                              </Text>
                            </View>
                          </View>
                        );
                      } else if (dependance.simulateurid === 89 || dependance.simulateurid === 96) {
                        return (
                          <View style={styles.superEmol}>
                            <Text style={styles.ctnEmol}>Cautionnement sans garantie</Text>
                            <View style={styles.ctnResultatsNb2}>
                              <Text style={styles.resultatsNb2}>
                                {(() => {
                                  const emolumentsActesTotal = parseFloat(
                                    requestSimu?.dependances[index]?.output?.emoluments_actes_total
                                  );
                                  const formattedValue =
                                    Math.ceil(emolumentsActesTotal).toLocaleString("en-EN").replace(/,/g, " ") +
                                    " € HT";
                                  return formattedValue;
                                })()}
                              </Text>
                            </View>
                          </View>
                        );
                      } else if (dependance.simulateurid === 90 || dependance.simulateurid === 97) {
                        return (
                          <View style={styles.superEmol}>
                            <Text style={styles.ctnEmol}>Cautionnement avec garantie</Text>
                            <View style={styles.ctnResultatsNb2}>
                              <Text style={styles.resultatsNb2}>
                                {(() => {
                                  const emolumentsActesTotal = parseFloat(
                                    requestSimu?.dependances[index]?.output?.emoluments_actes_total
                                  );
                                  const formattedValue =
                                    Math.ceil(emolumentsActesTotal).toLocaleString("en-EN").replace(/,/g, " ") +
                                    " € HT";
                                  return formattedValue;
                                })()}
                              </Text>
                            </View>
                          </View>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}

                <View style={styles.superEmol}>
                  {requestSimu.simulateurid === 32 || requestSimu.simulateurid === 83 ? (
                    <></>
                  ) : (
                    <Text style={styles.ctnEmol}>Emoluments de formalités</Text>
                  )}
                  <View style={styles.ctnResultatsNb2}>
                    <Text style={styles.resultatsNb2}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          //console.log(result[i].key)
                          if (result[i].key === "Emol_Form_Total") {
                            //console.log(result[i].value)
                            if (result[i].value !== "écrêtés") {
                              const numericValue = parseFloat(result[i].value);
                              const roundedValue = Math.ceil(numericValue);
                              const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            } else {
                              return result[i].value;
                            }
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                {simulateurObject?.data?.inputs?.promesse === 1 || simulateurObject?.data?.inputs?.promesse === "1" ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Honoraires de la promesse</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {parseInt(requestSimu.inputs["montant_promesse"]).toLocaleString("en-EN").replace(/,/g, " ") +
                          " € HT"}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>

          <View style={styles.blockRight}>
            {simulateurObject?.data?.DMTG?.length > 0 ? (
              <View style={styles.blockFiscalite2}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") tva = parseFloat(result[i].value);
                          else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const totalValue = tva + tresor;
                        const roundedTotal = Math.ceil(totalValue);
                        const formattedValue = roundedTotal.toLocaleString("en-EN").replace(/,/g, " ") + " €";
                        return formattedValue;
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt3}>Dont DMTG</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "DMTG") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt2}>TVA</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.blockFiscalite}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            tva = parseFloat(result[i].value);
                          } else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const totalValue = tva + tresor;
                        const roundedTotal = Math.ceil(totalValue);
                        const formattedValue = roundedTotal.toLocaleString("en-EN").replace(/,/g, " ") + " €";
                        return formattedValue;
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  {requestSimu.simulateurid === 32 || requestSimu.simulateurid === 83 ? (
                    <Text style={styles.resultatsTxt2}>TVA sur honoraires</Text>
                  ) : (
                    <Text style={styles.resultatsTxt2}>TVA sur émoluments</Text>
                  )}
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            <View style={styles.blockDebours}>
              <Text style={styles.debours}>Debours</Text>
              <View style={styles.ctnResultatsNb}>
                <Text style={styles.resultatsNb}>
                  {(() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "Debours_total") {
                        const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      }
                    }
                    return "";
                  })()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.ctnChart}>
          <RenderChart />
        </View>

        {/* MAP FOR INPUTS*/}

        <View style={styles.rappel}>
          <Text style={styles.bodyFooterMappedTitle}>Rappel de votre simulation :</Text>
          {requestSimu?.departementid !== 0 ? (
            <Text style={styles.bodyFooterMapped}>
              Département concerné :{" "}
              {requestSimu.departementid < 10 ? `0${requestSimu.departementid}` : requestSimu.departementid}
              {/*TODO Afficher le nom du département*/}
            </Text>
          ) : (
            <></>
          )}
          {Object.keys(requestSimu?.inputs).map((key) => {
            if (key === "taxe_ile_de_france") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Taxe IDF 0,6% : Oui</Text>;
              }
            } else if (key === "zrr") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>ZRR : Oui</Text>;
              }
            } else if (key === "acte_en_main") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Acte en main : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Acte en main : Non</Text>;
              }
            } else if (key === "montant_vente") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant de la vente :{" "}
                  {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "montant_meuble") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant des meubles :{" "}
                  {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "montant_cautionnement") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant du cautionnement :{" "}
                  {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "promesse") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>Promesse : Oui {"\n"}</Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant de la promesse :{" "}
                      {parseInt(requestSimu.inputs["montant_promesse"]).toLocaleString("en-EN").replace(/,/g, " ") +
                        " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "urbanisme") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>Urbanisme : Oui {"\n"}</Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant urbanisme :{" "}
                      {parseInt(requestSimu.inputs["montant_urbanisme"]).toLocaleString("en-EN").replace(/,/g, " ") +
                        " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "pretHypo") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Prêt hypothécaire : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Prêt hypothécaire : Non</Text>;
              }
            } else if (key === "pretsPro") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Prêt aux professionels : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Prêt aux particuliers : Oui</Text>;
              }
            } else if (key === "sansGarantieHypo") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement sans garantie : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement sans garantie : Non</Text>;
              }
            } else if (key === "sansGarantieHypoPro") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement sans garantie pour prêts pro : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement sans garantie pour prêts pro : Non</Text>;
              }
            } else if (key === "avecGarantieHypo") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement avec garantie : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement avec garantie : Non</Text>;
              }
            } else if (key === "avecGarantieHypoPro") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement avec garantie pour prêts pro : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement avec garantie pour prêts pro : Non</Text>;
              }
            } else if (key === "montant_acquisition") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de l'acquisition :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_retrocession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant rétrocession ou substitution :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "pacte_preference") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Pacte de préférence : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Pacte de préférence : Non</Text>;
              }
            } else if (key === "action_resolutoire") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Action résolutoire ou droit de délaissement : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Action résolutoire ou droit de délaissement : Non</Text>;
              }
            } else if (key === "type_immeuble") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Type d'immeuble : En état d’achèvement ou neuf</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Type d'immeuble : Ancien</Text>;
              }
            } else if (key === "nombre_lots") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de lots à créer : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "nombre_diagnostics") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de diagnostics : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_exigible") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la partie exigible :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "tva_concerne") {
              if (requestSimu.inputs[key] === 0.2) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 20%</Text>;
              } else if (requestSimu.inputs[key] === 0.1) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 10%</Text>;
              } else if (requestSimu.inputs[key] === 0.085) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 8,5% (DOM)</Text>;
              } else if (requestSimu.inputs[key] === 0.055) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 5,5%</Text>;
              } else if (requestSimu.inputs[key] === 0.021) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 2,1% (DOM)</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA concerné : 0%</Text>;
              }
            } else if (key === "montant_lot_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lot le plus élevé :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_lot_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lot le plus faible :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_lot") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lots 1 ou 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_meuble_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des meubles :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_meuble_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des meubles :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_licitee") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la part licitée :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_immeuble") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur totale de l’immeuble :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_acquis") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des parts acquises :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_annuel") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du loyer annuel :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "duree_bail") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Durée du bail : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "nombre_page_copie") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de page(s) : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_loyer") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cumulé des loyers :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_residuelle") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur résiduelle des constructions :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_mensuel") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du loyer mensuel :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_versements") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant annuel des loyers et versements :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_diagnostic") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre diagnostics immobiliers : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_charges") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cumulé des charges :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_honoraires") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant HT des honoraires :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_cession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant fixé pour la cession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "regime_cession") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Régime de la cession : Régime spécial</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Régime de la cession : Régime droit commun</Text>;
              }
            } else if (key === "acquisition_zone_geographique") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Acquisition en zone géographique spécifique : Oui</Text>;
              }
            } else if (key === "cession_fond_artisanal") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>Cession en pleine propriété d’un fonds artisanal : Oui</Text>
                );
              }
            } else if (key === "cession_fond_artisanal" && requestSimu.inputs["conditions_speciales"] === 1) {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Respect des conditions spéciales : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Respect des conditions spéciales : Non</Text>;
              }
            } else if (key === "acquisition_brevet") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Acquisition de brevet ou marque : Oui</Text>;
              }
            } else if (key === "brevet_vendu_seul" && requestSimu.inputs["acquisition_brevet"] === 1) {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Brevet vendu : Seul</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Brevet vendu : Avec le fonds</Text>;
              }
            } else if (key === "brevet_vendu_seul" && requestSimu.inputs["brevet_exploite"] === 1) {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Brevet exploité : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Brevet exploité : Non</Text>;
              }
            } else if (key === "marchandises_neuves") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Vente de marchandises neuves : Oui</Text>;
              }
            } else if (key === "nombre_servitude") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre servitudes : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "prix_acte") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Prix exprimé dans l'acte : Oui</Text>;
              }
            } else if (key === "valeur_servitude") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur servitude(s) à créer :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "facturation_forfait") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Facturation de plusieurs forfaits : Oui</Text>;
              } else {
                return <Text style={styles.bodyFooterMapped}>Facturation de plusieurs forfaits : Non</Text>;
              }
            } else if (key === "nombre_forfait") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de forfaits : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "valeur_residuelle") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur résiduelle :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_venale") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur vénale :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "prix_cession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prix de cession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_investissement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant investissement :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "duree_bail") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Durée du contrat : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "nombre_copie_executoire") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de pages : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_loyer_mensuel_ht") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant HT mensuel des loyers :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "loyer_tva") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Loyers soumis à TVA : Oui</Text>;
              }
            } else if (key === "loyer_tva_taux" && requestSimu.inputs["loyer_tva"] === 1) {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA : 20%</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Taux de TVA : 5,5%</Text>;
              }
            } else if (key === "montant_frais_financier") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des frais financiers :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "terrain_a_batir") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Terrain à batir : Oui</Text>;
              } else {
                return <Text style={styles.bodyFooterMapped}>Terrain à batir : Non</Text>;
              }
            } else if (key === "engagement_construire") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Engagement de construire : Oui</Text>;
              } else {
                return <Text style={styles.bodyFooterMapped}>Engagement de construire : Non</Text>;
              }
            } else if (key === "type_calcul") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Calcul : Depuis la base demandée par le vendeur</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Calcul : Depuis le prix comprenant les honoraires de négociation
                  </Text>
                );
              }
            } else if (key === "montant_forfait") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant forfaitaire minimum :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_demande") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant demandé :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "taux_remuneration") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Rémunération souhaitée :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "archivage") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Archivage : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Archivage : Non</Text>;
              }
            } else if (key === "valeur_transmise") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur transmise :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_page") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de pages : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "etat_civil") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Pièces d’état-civil demandées : Oui</Text>;
              }
            } else if (key === "comedec") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Recours à Comedec : Oui</Text>;
              }
            } else if (key === "nombre_comedec_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de Comedec à 3,56€ : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_comedec_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de Comedec à 0,56€ : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_copie_authentique") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "copie authentique" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_copie_libre") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre pages "copie libre" : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_archivage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "archivage numérisé" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "reserve_usufruit") {
              if (requestSimu.inputs[key] === 1) {
                if (requestSimu.inputs["age_usufruitier_2"] === 0) {
                  return (
                    <>
                      <Text style={styles.bodyFooterMapped}>Réserve d'usufruit : Oui {"\n"}</Text>
                      <Text style={styles.bodyFooterMapped}>Réserve d'usufruit : Oui {"\n"}</Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge de l'usufruitier : {requestSimu.inputs["age_usufruitier_1"]} ans
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant de l'usufruit :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Usufruit_1") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant de la nue-propriété :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Nue_propriete_1") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Text style={styles.bodyFooterMapped}>Réserve d'usufruit : Oui {"\n"}</Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge du premier Usufruitier : {requestSimu.inputs["age_usufruitier_1"]} {"\n"}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge du premier usufruitier : {requestSimu.inputs["age_usufruitier_1"]} ans {"\n"}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant d'usufruit :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Usufruit_1") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant de nue-propriété :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Nue_propriete_1") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge du second usufruitier : {requestSimu.inputs["age_usufruitier_2"]} ans
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant d'usufruit :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Usufruit_2") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Montant de nue-propriété :{" "}
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Nue_propriete_2") {
                              const montantNuePropriete = formatMontant(result[i].value);
                              return montantNuePropriete;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </>
                  );
                }
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Réserve d'usufruit : Non</Text>;
              }
            } else if (key === "nombre_donateurs") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Nombre donateurs : Un donateur</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre donateurs : Deux donateurs</Text>;
              }
            } else if (key === "montant_donateur_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_donateur_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "donation_especes") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Donation d'espèces : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Donation d'espèces : Non</Text>;
              }
            } else if (key === "montant_especes_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant espèces donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_especes_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant espèces donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_spf") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre SPF : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_fiscal_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse immobilière donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_fiscal_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse immobilière donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "reversion_usufruit") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Clause de réversion d'usufruit : Oui</Text>;
              } else {
                return <Text style={styles.bodyFooterMapped}>Clause de réversion d'usufruit : Non</Text>;
              }
            } else if (key === "DMTG") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>Calcul des DMTG : Oui</Text>
                    <Text style={styles.bodyFooterMappedItalic}>
                      Vous trouverez le détail de votre calcul de DMTG ci-après
                    </Text>
                  </>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Calcul des DMTG : Non</Text>;
              }
            } else if (key === "nombre_donateurs") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Donation : Cumulative si prédécès ou réalisée par un donateur
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 2) {
                return <Text style={styles.bodyFooterMapped}>Donation : conjonctive</Text>;
              }
            } else if (key === "montant_rapports") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant donations à rapporter et à réincorporer :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_rapports_especes") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont espèces : {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_rapport_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>
                      Montant rapports 1er donateur :{" "}
                      {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Dont espèces :{" "}
                      {parseInt(requestSimu.inputs["montant_rapport_especes_1"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "montant_rapport_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>
                      Montant rapports 2ème donateur :{" "}
                      {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Dont espèces :{" "}
                      {parseInt(requestSimu.inputs["montant_rapport_especes_2"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "montant_donateur_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Somme donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_donateur_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Somme donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_NP_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant Nue-Propriété donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_NP_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant Nue-Propriété donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_brut") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Actif brut de succession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "total_rapportable") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant total des donations rapportables, de plus ou moins de 15 ans :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_passif") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Passif de succession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Actif net de succession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            }else if (key === "montant_succession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la succession :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "premier_deces") {
              if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Premier décès d’une communauté : Oui</Text>;
              } else if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Premier décès d’une communauté : Non</Text>;
              }
            } else if (key === "valeur_biens_communs") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens communs transmis :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_propres") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres transmis :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_transmis") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens transmis :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_publicite_fonciere") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre SPF : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "valeur_biens_propres_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres 1er époux transmis :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_propres_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres 2nd époux transmis :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "masse_brute") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse brute partagée :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "actif_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Actif net partagé :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_cumulee") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur cumulée biens immobiliers partagés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "droit_partage_reduit") {
              if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Droit partage concerné : Normal</Text>;
              } else if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Droit partage concerné : Réduit</Text>;
              }
            } else if (key === "montant_soulte") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la soulte :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_passif") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du passif :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "codicilles") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Codicille(s) déposé(s) conservé(s) à l’étude : Oui</Text>;
              }
            } else if (key === "nombre_codicilles") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de codicilles déposés : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "envoi_possession") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Envoi en possession : Oui</Text>;
              }
            } else if (key === "valeur_bien") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_declares") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens déclarés et/ou apportés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "formalites_publication") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Formalités de publication au SPF : Oui</Text>;
              }
            } else if (key === "valeur_biens_immo") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_enfants") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre enfants majeurs : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_brut") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif net partagé :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_partages") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens immobiliers partagés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_part_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales faisant l’objet du partage partiel :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ")}
                  </Text>
                );
              }
            } else if (key === "nombre_parts_capital") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales composant le capital social :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ")}
                  </Text>
                );
              }
            } else if (key === "montant_honoraire") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant honoraire HT :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_apportes") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens immobiliers apportés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_spf") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien apporté au SPF n°1 [n] :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "apport_pur_simple") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont apport à titre pur et simple :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "apport_onereux") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont apport à titre onéreux :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "pret_realise") {
              if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Prêt réalisé : Dans l'acte principal</Text>;
              } else if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Prêt réalisé : Par acte séparé</Text>;
              }
            } else if (key === "montant_pret") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant prêt ou capitaux empruntés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret_hp") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant garanti par une hypothèque légale de préteur de deniers :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret_hc") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant garanti par l'Hypothèque Conventionnelle :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du prêt :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "cautionnement_realise") {
              if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement réalisé : Dans l'acte principal</Text>;
              } else if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Cautionnement réalisé : Par acte séparé</Text>;
              }
            } else if (key === "montant_cautionnement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cautionnement :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nantissement_realise") {
              if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Nantissement réalisé : Dans l'acte principal</Text>;
              } else if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Nantissement réalisé : Par acte séparé</Text>;
              }
            } else if (key === "montant_nantissement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cautionnement :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_creance") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant créance :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pouvoir") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Frais de pouvoir :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_quittance") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant quittance :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_prorogation") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant prorogation :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            }
            return <></>;
          })}

          {requestSimu.hasOwnProperty("dependances") ? (
            requestSimu?.dependances.map((dependance, index) => {
              if (servitudes.includes(requestSimu?.simulateurid) && requestSimu?.inputs["prix_acte"] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prix de la servitude {index + 1} :{" "}
                    {parseInt(requestSimu?.dependances[index].prix_servitude)
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              } else if (valeurSPF.includes(requestSimu?.simulateurid)) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du SPF {index + 1} :{" "}
                    {parseInt(requestSimu?.dependances[index].valeur_spf).toLocaleString("en-EN").replace(/,/g, " ") +
                      " €"}
                  </Text>
                );
              } else if (dependance.simulateurid === 84) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Par acte séparé</Text>
                    ) : requestSimu?.dependances[index].inputs["pret_realise"] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Dans l'acte principal</Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant prêt ou capitaux empruntés :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par une hypothèque légale de préteur de deniers :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hp"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hc"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 85) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Par acte séparé</Text>
                    ) : requestSimu?.dependances[index].inputs["pret_realise"] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Dans l'acte principal</Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant total du prêt ou capitaux empruntés :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant du prêt soumis à un prêt aidé :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hp"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hc"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 92) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Par acte séparé</Text>
                    ) : requestSimu?.dependances[index].inputs["pret_realise"] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>Prêt réalisé : Dans l'acte principal</Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant prêt ou capitaux empruntés :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par une hypothèque légale de préteur de deniers :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hp"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_pret_hc"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 89 || dependance.simulateurid === 96) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["cautionnement_realise"] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>Acte de cautionnement réalisé : Par acte séparé</Text>
                    ) : requestSimu?.dependances[index].inputs["cautionnement_realise"] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>Acte de cautionnement réalisé : Dans l'acte principal</Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant cautionnement sans garantie :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_cautionnement"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 90 || dependance.simulateurid === 97) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["cautionnement_realise"] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>Acte de cautionnement réalisé : Par acte séparé</Text>
                    ) : requestSimu?.dependances[index].inputs["cautionnement_realise"] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>Acte de cautionnement réalisé : Dans l'acte principal</Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant cautionnement avec garantie :{" "}
                      {parseInt(requestSimu?.dependances[index].inputs["montant_cautionnement"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              }
              return <></>;
            })
          ) : (
            <></>
          )}
        </View>

        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
            peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
        </View>
      </Page>

      {/*  P2  */}
      <Page>
        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 5,
            marginTop: 40,
            border: "1px solid #eeeae5",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              width: "100%",
              height: 28,
              backgroundColor: "#b5a58e",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              borderBottom: "1px solid #eeeae5",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 10,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {requestSimu?.simulateurid === 32 || requestSimu?.simulateurid === 83
                ? "DÉTAIL HONORAIRES HT"
                : "DÉTAIL ÉMOLUMENTS HT"}
            </Text>
          </View>

          {requestSimu?.simulateurid === 32 || requestSimu?.simulateurid === 83 ? (
            <></>
          ) : (
            <>
              <View
                style={{
                  width: "100%",
                  height: 24,
                  // borderBottomRightRadius: 4,
                  // borderBottomLeftRadius: 4,
                  borderBottom: "1px solid #eeeae5",
                  backgroundColor: "#eeeae5",
                  textAlign: "left",
                  fontFamily: "RobotoBold",
                  fontSize: 9.5,
                }}
              >
                <Text
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                >
                  ÉMOLUMENTS D’ACTE HT
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  borderBottom: "1px solid #eeeae5",
                  display: "flex",
                  fontSize: 8.5,
                  flexDirection: "column",
                  gap: -5,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                    }}
                  />

                  <View
                    style={{
                      flex: 0.2,
                      borderLeft: "1px solid #eeeae5",
                      borderBottom: "1px solid #eeeae5",
                      textAlign: "center",
                      height: 18,
                      marginTop: -3,
                    }}
                  >
                    <Text style={{ marginTop: 3.8, fontFamily: "RobotoBold" }}>Base de calcul</Text>
                  </View>
                  <View
                    style={{
                      flex: 0.2,
                      borderLeft: "1px solid #eeeae5",
                      borderBottom: "1px solid #eeeae5",
                      textAlign: "center",
                      height: 18,
                      marginTop: -3,
                    }}
                  >
                    <Text style={{ marginTop: 3.8, fontFamily: "RobotoBold" }}>Montant</Text>
                  </View>
                </View>

                {emolActeValues.map((value, index) => {
                  const baseEmolValue = baseEmolValues[index];
                  if (requestSimu?.simulateurid === 34 || requestSimu?.simulateurid === 35) {
                    return (
                      <View
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments d’acte servitude {index + 1}
                            {TblArticleValue !== null && (
                              <>
                                {" - "}
                                {TblArticleValue}
                              </>
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: 3.8,
                              marginBottom: "auto",
                            }}
                          >
                            {baseEmolValue}
                          </Text>
                        </View>
                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {value}
                        </Text>
                      </View>
                    );
                  } else {
                    return <></>;
                  }
                })}

                {requestSimu.simulateurid === 39 ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 18,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Émoluments d’acte HT
                        {TblArticleValue !== null && (
                          <>
                            {" - "}
                            {TblArticleValue}
                          </>
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 18,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 3.8,
                          marginBottom: "auto",
                        }}
                      >
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");

                            if (emol1Value > emol2Value) {
                              if (result[i].key === "base_emol_1") {
                                return formattedValue + " €";
                              }
                            } else {
                              if (result[i].key === "base_emol_2") {
                                return formattedValue + " €";
                              }
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                    <Text
                      style={{
                        flex: 0.2,
                        marginTop: 3.8,
                        textAlign: "center",
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");

                          if (emol1Value > emol2Value) {
                            if (result[i].key === "emoluments_actes_1") {
                              return formattedValue + " €";
                            }
                          } else {
                            if (result[i].key === "emoluments_actes_2") {
                              return formattedValue + " €";
                            }
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}

                {((simulateurObject.data.inputs.montant_vente !== null &&
                  simulateurObject.data.inputs.montant_vente !== undefined) ||
                  (simulateurObject.data.inputs.montant_acquisition !== null &&
                    simulateurObject.data.inputs.montant_acquisition !== undefined)) &&
                requestSimu.hasOwnProperty("dependances") ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 18,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {simulateurObject.data.inputs.montant_vente
                          ? "Émoluments d’acte de vente HT"
                          : "Émoluments d’acte d'acquisition HT"}
                        {TblArticleValue !== null && (
                          <>
                            {" - "}
                            {TblArticleValue}
                          </>
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 18,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 3.8,
                          marginBottom: "auto",
                        }}
                      >
                        {parseInt(
                          (
                            simulateurObject.data.inputs.montant_vente ||
                            simulateurObject.data.inputs.montant_acquisition
                          ).toFixed(2)
                        )
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")
                          .toString() + " €"}
                      </Text>
                    </View>
                    <Text
                      style={{
                        flex: 0.2,
                        marginTop: 3.8,
                        textAlign: "center",
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "emoluments_actes") {
                            return result[i].value;
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                ) : simulateurObject?.data?.inputs?.montant_donateur_1 !== null &&
                  simulateurObject?.data?.inputs?.montant_donateur_1 !== undefined ? (
                  <>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <View
                        style={{
                          flex: 0.6,
                          paddingRight: 4,
                          borderRight: "1px solid #eeeae5",
                          height: 18,
                        }}
                      >
                        <Text
                          style={{
                            width: "100%",
                            textAlign: "right",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          Émoluments d’acte - 1er donateur
                          {TblArticleValue !== null && (
                            <>
                              {" - "}
                              {TblArticleValue}
                            </>
                          )}
                        </Text>
                      </View>
                      <View
                        style={{
                          height: 18,
                          flex: 0.2,
                          borderRight: "1px solid #eeeae5",
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: 3.8,
                            marginBottom: "auto",
                          }}
                        >
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "base_emol_1") {
                                const formattedValue = parseInt(result[i].value)
                                  .toLocaleString("en-EN")
                                  .replace(/,/g, " ");
                                return (formattedValue + " €").toString();
                              }
                            }
                            return "";
                          })()}
                        </Text>
                      </View>
                      <Text
                        style={{
                          flex: 0.2,
                          marginTop: 3.8,
                          textAlign: "center",
                        }}
                      >
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emoluments_actes_1") {
                              return result[i].value;
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>

                    {simulateurObject?.data?.inputs?.nombre_donateurs === 2 ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments d’acte - 2ème donateur
                            {TblArticleValue !== null && (
                              <>
                                {" - "}
                                {TblArticleValue}
                              </>
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: 3.8,
                              marginBottom: "auto",
                            }}
                          >
                            {(() => {
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].key === "base_emol_2") {
                                  const formattedValue = parseInt(result[i].value)
                                    .toLocaleString("en-EN")
                                    .replace(/,/g, " ");
                                  return (formattedValue + " €").toString();
                                }
                              }
                              return "";
                            })()}
                          </Text>
                        </View>
                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "emoluments_actes_2") {
                                return result[i].value;
                              }
                            }
                            return "";
                          })()}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {requestSimu?.simulateurid !== 34 &&
                      requestSimu?.simulateurid !== 35 &&
                      requestSimu?.simulateurid !== 32 &&
                      requestSimu?.simulateurid !== 83 &&
                      requestSimu?.simulateurid !== 39 && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.6,
                              paddingRight: 4,
                              borderRight: "1px solid #eeeae5",
                              height: 18,
                            }}
                          >
                            <Text
                              style={{
                                width: "100%",
                                textAlign: "right",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Émoluments d’acte HT
                              {TblArticleValue !== null && (
                                <>
                                  {" - "}
                                  {TblArticleValue}
                                </>
                              )}
                            </Text>
                          </View>
                          <View
                            style={{
                              height: 18,
                              flex: 0.2,
                              borderRight: "1px solid #eeeae5",
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                width: "100%",
                                marginTop: 3.8,
                                marginBottom: "auto",
                              }}
                            >
                              {(() => {
                                for (let i = 0; i < result.length; i++) {
                                  if (result[i].key === "base_emol") {
                                    const formattedValue = parseInt(result[i].value)
                                      .toLocaleString("en-EN")
                                      .replace(/,/g, " ");
                                    return (formattedValue + " €").toString();
                                  }
                                }
                                return "";
                              })()}
                            </Text>
                          </View>
                          <Text
                            style={{
                              flex: 0.2,
                              marginTop: 3.8,
                              textAlign: "center",
                            }}
                          >
                            {(() => {
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].key === "emoluments_actes_total") {
                                  return result[i].value;
                                }
                              }
                              return "";
                            })()}
                          </Text>
                        </View>
                      )}
                  </>
                )}

                {isDependanceValid(simu84Index) || isDependanceValid(simu85Index) || isDependanceValid(simu92Index) ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 18,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Émoluments d’acte de prêt HT
                        {TblArticleValue !== null && (
                          <>
                            {" - "}
                            {DepsArticles[0]}
                          </>
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 18,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 3.8,
                          marginBottom: "auto",
                        }}
                      >
                        {isDependanceValid(simu84Index)
                          ? formattedBaseEmolValue84
                          : isDependanceValid(simu85Index)
                          ? formattedBaseEmolValue85
                          : isDependanceValid(simu92Index)
                          ? formattedBaseEmolValue92
                          : null}
                      </Text>
                    </View>
                    <Text
                      style={{
                        flex: 0.2,
                        marginTop: 3.8,
                        textAlign: "center",
                      }}
                    >
                      {isDependanceValid(simu84Index)
                        ? formattedEmolValue84
                        : isDependanceValid(simu85Index)
                        ? formattedEmolValue85
                        : isDependanceValid(simu92Index)
                        ? formattedEmolValue92
                        : null}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}

                {isDependanceValid(simu89Index) || isDependanceValid(simu96Index) ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 18,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Émoluments d’acte de cautionnement sans garantie HT
                        {TblArticleValue !== null && (
                          <>
                            {" - "}
                            {DepsArticles[1]}
                          </>
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 18,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 3.8,
                          marginBottom: "auto",
                        }}
                      >
                        {isDependanceValid(simu89Index)
                          ? formattedBaseEmolValue89
                          : isDependanceValid(simu96Index)
                          ? formattedBaseEmolValue96
                          : null}
                      </Text>
                    </View>
                    <Text
                      style={{
                        flex: 0.2,
                        marginTop: 3.8,
                        textAlign: "center",
                      }}
                    >
                      {isDependanceValid(simu89Index)
                        ? formattedEmolValue89
                        : isDependanceValid(simu96Index)
                        ? formattedEmolValue96
                        : null}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
                {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 18,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Émoluments d’acte de cautionnement avec garantie HT
                        {TblArticleValue !== null && (
                          <>
                            {" - "}
                            {DepsArticles[2] ? DepsArticles[2] : DepsArticles[1]}
                          </>
                        )}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 18,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 3.8,
                          marginBottom: "auto",
                        }}
                      >
                        {isDependanceValid(simu90Index)
                          ? formattedBaseEmolValue90
                          : isDependanceValid(simu97Index)
                          ? formattedBaseEmolValue97
                          : null}
                      </Text>
                    </View>
                    <Text
                      style={{
                        flex: 0.2,
                        marginTop: 3.8,
                        textAlign: "center",
                      }}
                    >
                      {isDependanceValid(simu90Index)
                        ? formattedEmolValue90
                        : isDependanceValid(simu97Index)
                        ? formattedEmolValue97
                        : null}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 2,
                    paddingBottom: 2,
                    marginBottom: -3,
                  }}
                >
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      borderTop: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontFamily: "RobotoMedium",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Total des émoluments d’acte HT (arrondi)
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 25,
                      flex: 0.2,
                      borderRight: "1px solid #eeeae5",
                      borderTop: "1px solid #eeeae5",
                    }}
                  />
                  <View
                    style={{
                      height: 25,
                      flex: 0.2,
                      borderTop: "1px solid #eeeae5",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontFamily: "RobotoBold",
                        marginTop: 7.3,
                      }}
                    >
                      {requestSimu?.simulateurid !== 32 || requestSimu?.simulateurid !== 83
                        ? (() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "emoluments_actes_total") {
                                const numericValue = parseFloat(result[i].value);
                                const roundedValue = Math.ceil(numericValue);
                                const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                                return (formattedValue + " €").toString();
                              }
                            }
                            return "";
                          })()
                        : "0 €"}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  height: 24,
                  borderBottom: "1px solid #eeeae5",
                  backgroundColor: "#eeeae5",
                  textAlign: "left",
                  fontFamily: "RobotoMedium",
                  fontSize: 9.5,
                }}
              >
                <Text
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                >
                  ÉMOLUMENTS DE FORMALITÉ HT
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  borderBottom: "1px solid #eeeae5",
                  display: "flex",
                  fontSize: 8.5,
                  flexDirection: "column",
                  gap: -5,
                }}
              >
                {persoEmol === null ? (
                  <>
                    {((simulateurObject.data.inputs.montant_vente !== null &&
                      simulateurObject.data.inputs.montant_vente !== undefined) ||
                      (simulateurObject.data.inputs.montant_acquisition !== null &&
                        simulateurObject.data.inputs.montant_acquisition !== undefined)) &&
                    requestSimu.hasOwnProperty("dependances") ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                          marginTop: -3,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {simulateurObject.data.inputs.montant_vente
                              ? "Émoluments de formalités acte de vente HT"
                              : "Émoluments de formalités acte d'acquisition HT"}
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />

                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "Emol_Form") {
                                return result[i].value;
                              }
                            }
                            return 0;
                          })()}
                        </Text>
                      </View>
                    ) : (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                          marginTop: -3,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments de formalités HT
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />

                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "Emol_Form_Total") {
                                return result[i].value;
                              } else if (
                                result[i].key === "emoluments_formalites_total" &&
                                result[i].key !== "Emol_Form_Total"
                              ) {
                                return result[i].value;
                              }
                            }
                            return "0 €";
                          })()}
                        </Text>
                      </View>
                    )}

                    {isDependanceValid(simu84Index) ||
                    isDependanceValid(simu85Index) ||
                    isDependanceValid(simu92Index) ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments de formalités acte de prêt HT
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />

                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {isDependanceValid(simu84Index)
                            ? formattedEmolFormValue84
                            : isDependanceValid(simu85Index)
                            ? formattedEmolFormValue85
                            : isDependanceValid(simu92Index)
                            ? formattedEmolFormValue92
                            : null}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}

                    {isDependanceValid(simu89Index) || isDependanceValid(simu96Index) ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments de formalités acte de cautionnement sans garantie HT
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />
                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {isDependanceValid(simu89Index)
                            ? formattedEmolFormValue89
                            : isDependanceValid(simu96Index)
                            ? formattedEmolFormValue96
                            : null}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}

                    {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Émoluments de formalités acte de cautionnement avec garantie HT
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />
                        <Text
                          style={{
                            flex: 0.2,
                            marginTop: 3.8,
                            textAlign: "center",
                          }}
                        >
                          {isDependanceValid(simu90Index)
                            ? formattedEmolFormValue90
                            : isDependanceValid(simu97Index)
                            ? formattedEmolFormValue97
                            : null}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 2,
                    paddingBottom: 2,
                    marginBottom: -3,
                  }}
                >
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      borderTop: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontFamily: "RobotoMedium",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Total des émoluments de formalités HT (arrondi)
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 25,
                      flex: 0.2,
                      borderRight: "1px solid #eeeae5",
                      borderTop: "1px solid #eeeae5",
                    }}
                  />
                  <View
                    style={{
                      height: 25,
                      flex: 0.2,
                      borderTop: "1px solid #eeeae5",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontFamily: "RobotoBold",
                        marginTop: 7.3,
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Emol_Form_Total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "0 €";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}

          {/* END BLOC 2 */}

          {simulateurObject?.data?.inputs?.promesse === 1 ||
          simulateurObject?.data?.inputs?.promesse === "1" ||
          requestSimu.simulateurid === 32 ||
          requestSimu.simulateurid === 83 ||
          requestSimu.simulateurid === 81 ||
          requestSimu.simulateurid === 82 ? (
            <View>
              <View
                style={{
                  width: "100%",
                  height: 24,
                  borderBottom: "1px solid #eeeae5",
                  backgroundColor: "#eeeae5",
                  textAlign: "left",
                  fontFamily: "RobotoMedium",
                  fontSize: 9.5,
                }}
              >
                <Text
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                >
                  HONORAIRES HT
                </Text>
              </View>

              {simulateurObject?.data?.inputs?.promesse === 1 || simulateurObject?.data?.inputs?.promesse === "1" ? (
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: 8.5,
                    flexDirection: "column",
                    gap: -5,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 2,
                      paddingBottom: 2,
                      marginTop: -3,
                      marginBottom: -3,
                    }}
                  >
                    <View
                      style={{
                        flex: 0.6,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        height: 25,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          textAlign: "right",
                          fontFamily: "RobotoMedium",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Promesse de vente (ou autres honoraires libres) HT
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 25,
                        flex: 0.2,
                        borderRight: "1px solid #eeeae5",
                      }}
                    />
                    <View
                      style={{
                        height: 25,
                        flex: 0.2,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          fontFamily: "RobotoBold",
                          marginTop: 7.3,
                        }}
                      >
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emol_promesse") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " €").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <>
                  {requestSimu.simulateurid === 32 ||
                  requestSimu.simulateurid === 83 ||
                  requestSimu.simulateurid === 81 ||
                  requestSimu.simulateurid === 82 ? (
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        fontSize: 8.5,
                        flexDirection: "column",
                        gap: -5,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                          marginTop: -3,
                          marginBottom: -3,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 25,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              fontFamily: "RobotoMedium",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Honoraires HT
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 25,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />
                        <View
                          style={{
                            height: 25,
                            flex: 0.2,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              fontFamily: "RobotoBold",
                              marginTop: 7.3,
                            }}
                          >
                            {(() => {
                              const montant_honoraires =
                                parseInt(requestSimu?.inputs?.montant_honoraires.toFixed(2))
                                  .toLocaleString("en-EN")
                                  .replace(/,/g, " ")
                                  .toString() + " €";

                              return montant_honoraires;
                            })()}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </View>
          ) : (
            <></>
          )}
        </View>
        {/* END BLOC 3 */}
        {persoEmol === null ? (
          <View
            style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              height: 28,
              backgroundColor: "#b5a58e",
              marginTop: 30,
              borderRadius: 4,
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Text
              style={{
                fontFamily: "RobotoMedium",
                fontSize: 9.5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {requestSimu?.simulateurid === 32 || requestSimu?.simulateurid === 83
                ? "TOTAL HONORAIRES HT"
                : "TOTAL ÉMOLUMENTS HT (arrondi)"}
            </Text>

            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 9.5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {(() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "Emol_total") {
                    const numericValue = parseFloat(result[i].value);
                    const roundedValue = Math.ceil(numericValue);
                    const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                    return (formattedValue + " €").toString();
                  }
                }
                return "";
              })()}
            </Text>
          </View>
        ) : (
          <></>
        )}

        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
            peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
        </View>
      </Page>
      {/*  END P2  */}

      {/* PERSONALISATION P2 */}
      {persoEmol !== null ? (
        <Page>
          <View
            style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 5,
              marginTop: 40,
              border: "1px solid #eeeae5",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                width: "100%",
                height: 24,
                borderBottom: "1px solid #eeeae5",
                backgroundColor: "#eeeae5",
                textAlign: "left",
                fontFamily: "RobotoBold",
                fontSize: 9.5,
              }}
            >
              <Text
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 10,
                }}
              >
                DÉTAIL DES ÉMOLUMENTS DE FORMALITÉ HT SÉLECTIONNÉS
              </Text>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                fontSize: 8.5,
                flexDirection: "column",
                gap: -5,
              }}
            >
              {1 !== null && 1 !== undefined ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 2,
                    borderBottom: "1px solid #eeeae5",
                  }}
                >
                  <View
                    style={{
                      flex: 0.5,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      height: 30,
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: 30,
                        textAlign: "left",
                        marginTop: "auto",
                        marginBottom: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Nature des formalités
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 30,
                      flex: 0.166,
                      borderRight: "1px solid #eeeae5",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: 4,
                        fontWeight: "bold",
                      }}
                    >
                      N° du Tableau 5
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Article Arrêté
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 30,
                      flex: 0.166,
                      borderRight: "1px solid #eeeae5",
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Coût de la formalité
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 30,
                      flex: 0.166,
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Nombre de formalités
                    </Text>
                  </View>
                </View>
              ) : (
                <></>
              )}

              {persoEmol?.map((item, index) => {
                return item.nombre > 0 ? (
                  <View
                    style={
                      index % 2 === 1
                        ? {
                            backgroundColor: "#f8f6f4",
                            display: "flex",
                            flexDirection: "row",
                            // paddingBottom: 4,
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            // paddingBottom: 6,
                          }
                    }
                  >
                    <View
                      style={{
                        flex: 0.5,
                        paddingRight: 4,
                        borderRight: "1px solid #eeeae5",
                        minHeight: 30,
                        paddingTop: 0,
                        paddingBottom: 6,
                        marginTop: 0,
                      }}
                    >
                      <Text
                        style={{
                          marginLeft: 5,
                          textAlign: "left",
                          marginTop: 13,
                          fontWeight: "bold",
                        }}
                      >
                        {item.formalite}
                      </Text>
                    </View>
                    <View
                      style={{
                        minHeight: 30,
                        flex: 0.166,
                        borderRight: "1px solid #eeeae5",
                      }}
                    >
                      <View style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <Text
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: 0,
                            fontWeight: "bold",
                          }}
                        >
                          {item.article.split("Art")[0]}
                        </Text>
                        <Text
                          style={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Art{item.article.split("Art")[1]}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        minHeight: 30,
                        flex: 0.166,
                        borderRight: "1px solid #eeeae5",
                        textAlign: "center",
                      }}
                    >
                      <View style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginTop: 0,
                          }}
                        >
                          {item.montant} €
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        minHeight: 30,
                        flex: 0.166,
                        textAlign: "center",
                      }}
                    >
                      <View style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginTop: 0,
                          }}
                        >
                          {item.nombre}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                );
              })}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  marginBottom: 2,
                }}
              >
                <View
                  style={{
                    flex: 0.5,
                    paddingRight: 2,
                    borderRight: "1px solid #eeeae5",
                    borderTop: "1px solid #eeeae5",
                    height: 25,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "right",
                      fontFamily: "RobotoMedium",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Total des émoluments de formalités sélectionnés HT
                  </Text>
                </View>
                <View
                  style={{
                    flex: 0.5,
                    borderTop: "1px solid #eeeae5",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontFamily: "RobotoBold",
                      marginTop: 7.3,
                    }}
                  >
                    {parseInt(
                      (() => {
                        let tempo = 0;
                        for (let i = 0; i < persoEmol.length; i++) {
                          if (persoEmol[i].nombre > 0) {
                            tempo += parseFloat(parseInt(persoEmol[i].nombre) * parseFloat(persoEmol[i].montant));
                          }
                        }
                        return tempo;
                      })().toFixed(2)
                    ) + " €"}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              height: 28,
              backgroundColor: "#b5a58e",
              marginTop: 30,
              borderRadius: 4,
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 9.5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              TOTAL ÉMOLUMENTS HT
            </Text>

            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 9.5,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {parseInt(
                (
                  checkAndTransformNullToZero(
                    simulateurObject?.data?.dependances?.[simu84Index]?.output?.emoluments_actes_total
                  ) +
                  checkAndTransformNullToZero(
                    simulateurObject?.data?.dependances?.[simu92Index]?.output?.emoluments_actes_total
                  ) +
                  checkAndTransformNullToZero(
                    simulateurObject?.data?.dependances?.[simu89Index]?.output?.emoluments_actes_total
                  ) +
                  checkAndTransformNullToZero(
                    simulateurObject?.data?.dependances?.[simu90Index]?.output?.emoluments_actes_total
                  ) +
                  (() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "emoluments_actes_total") {
                        return parseInt(result[i].value);
                      }
                    }
                    return 0;
                  })() +
                  (() => {
                    let tempo = 0;
                    for (let i = 0; i < persoEmol.length; i++) {
                      if (persoEmol[i].nombre > 0) {
                        tempo += parseFloat(parseInt(persoEmol[i].nombre) * parseFloat(persoEmol[i].montant));
                      }
                    }
                    return tempo;
                  })()
                ).toFixed(2)
              )
                .toLocaleString("en-EN")
                .replace(/,/g, " ")
                .toString() + " €"}
            </Text>
          </View>

          <View style={styles.bodyFooterCtn} fixed>
            <Text style={styles.bodyFooterMedium}>
              IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
              peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
              adressés par virement au minimum 48h-72h à l'avance.
            </Text>
            <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
          </View>
        </Page>
      ) : (
        <></>
      )}
      {/* END PERSONALISATION P2  */}
      {/*  P3  */}
      <Page>
        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            marginTop: 40,
            border: "1px solid #d8e9ec",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              width: "100%",
              height: 28,
              backgroundColor: "#55a0ab",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              borderBottom: "1px solid #d8e9ec",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 10,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              DÉTAIL FISCALITÉ
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              height: 24,
              borderBottom: "1px solid #d8e9ec",
              backgroundColor: "#d8e9ec",
              textAlign: "left",
              fontFamily: "RobotoBold",
              fontSize: 9.5,
            }}
          >
            <Text
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: 10,
              }}
            >
              TVA SUR ÉMOLUMENTS
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              fontSize: 8.5,
              flexDirection: "column",
              gap: -5,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: -3,
                marginBottom: -3,
              }}
            >
              <View
                style={{
                  flex: 0.6,
                  paddingRight: 4,
                  borderRight: "1px solid #eeeae5",
                  height: 25,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    textAlign: "right",
                    fontFamily: "RobotoMedium",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  {requestSimu?.simulateurid === 32 || requestSimu?.simulateurid === 83
                    ? "TVA sur honoraires"
                    : "TVA sur émoluments (arrondi)"}
                </Text>
              </View>
              <View
                style={{
                  height: 25,
                  flex: 0.2,
                }}
              />
              <View
                style={{
                  height: 25,
                  flex: 0.2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontFamily: "RobotoBold",
                    marginTop: 7.3,
                  }}
                >
                  {(() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "TVA_total") {
                        const numericValue = parseFloat(result[i].value);
                        const roundedValue = Math.ceil(numericValue);
                        const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      }
                    }
                    return "";
                  })()}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              height: 24,
              borderBottom: "1px solid #d8e9ec",
              backgroundColor: "#d8e9ec",
              textAlign: "left",
              fontFamily: "RobotoBold",
              fontSize: 9.5,
            }}
          >
            <Text
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: 10,
              }}
            >
              DÉTAIL DROITS DUS AU TRÉSOR PUBLIC
            </Text>
          </View>

          {result.map((object) => {
            if (object.key === "Tresor_total" && object.value !== "0 €") {
              return (
                <>
                  {tresorTotalValue !== droitEtatValue ? (
                    <>
                      <View
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #eeeae5",
                          display: "flex",
                          fontSize: 8.5,
                          flexDirection: "column",
                          gap: -5,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.6,
                              paddingRight: 4,
                            }}
                          />

                          <View
                            style={{
                              flex: 0.2,
                              borderLeft: "1px solid #eeeae5",
                              borderBottom: "1px solid #eeeae5",
                              textAlign: "center",
                              height: 18,
                              marginTop: -3,
                            }}
                          >
                            <Text
                              style={{
                                marginTop: 3.8,
                                fontFamily: "RobotoBold",
                              }}
                            >
                              Base de calcul
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 0.2,
                              borderLeft: "1px solid #eeeae5",
                              borderBottom: "1px solid #eeeae5",
                              textAlign: "center",
                              height: 18,
                              marginTop: -3,
                            }}
                          >
                            <Text
                              style={{
                                marginTop: 3.8,
                                fontFamily: "RobotoBold",
                              }}
                            >
                              Montant
                            </Text>
                          </View>
                        </View>

                        <>
                          {simulateurObject.data.inputs.montant_vente !== null &&
                          simulateurObject.data.inputs.montant_vente !== undefined &&
                          requestSimu.hasOwnProperty("dependances") ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    fontFamily: "RobotoMedium",
                                  }}
                                >
                                  Droits dus au trésor : acte de vente
                                </Text>
                              </View>
                              <>
                                {simulateurObject.data.inputs.acte_en_main === 1 ? (
                                  <>
                                    <View
                                      style={{
                                        height: 18,
                                        flex: 0.2,
                                        borderRight: "1px solid #eeeae5",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                          marginTop: 3.8,
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {(() => {
                                          let baseTresorValue = null;
                                          for (let i = 0; i < result.length; i++) {
                                            if (result[i].key === "base_tresor") {
                                              baseTresorValue = parseInt(result[i].value);
                                            }
                                          }
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          } else {
                                            <></>;
                                          }
                                          return "";
                                        })()}
                                      </Text>
                                    </View>
                                    <Text
                                      style={{
                                        flex: 0.2,
                                        marginTop: 3.8,
                                        textAlign: "center",
                                        fontFamily: "RobotoBold",
                                      }}
                                    >
                                      {(() => {
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "Tresor") {
                                            return result[i].value;
                                          }
                                        }
                                        return "";
                                      })()}
                                    </Text>
                                  </>
                                ) : (
                                  <>
                                    <View
                                      style={{
                                        height: 18,
                                        flex: 0.2,
                                        borderRight: "1px solid #eeeae5",
                                      }}
                                    />
                                    <Text
                                      style={{
                                        flex: 0.2,
                                        marginTop: 3.8,
                                        textAlign: "center",
                                        fontFamily: "RobotoBold",
                                      }}
                                    >
                                      {(() => {
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "Tresor") {
                                            return result[i].value;
                                          }
                                        }
                                        return "";
                                      })()}
                                    </Text>
                                  </>
                                )}
                              </>
                            </View>
                          ) : (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 5,
                                }}
                              ></View>
                              <View
                                style={{
                                  height: 5,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                            </View>
                          )}
                        </>
                        <>
                          {result.map((object) => {
                            if (
                              object.key === "droit_mutation" &&
                              requestSimu?.simulateurid !== 34 &&
                              requestSimu?.simulateurid !== 35
                            ) {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  {renderDMTO()}

                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseDmtoValue = null;
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_droit_mutation") {
                                            baseDmtoValue = parseInt(result[i].value);
                                          }
                                          if (result[i].key === "base_tresor") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseDmtoValue === null) {
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        } else {
                                          if (baseDmtoValue !== null) {
                                            const formattedValue = baseDmtoValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "droit_mutation") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {dmtoValues.map((value, index) => {
                            const baseTresorValue = baseTresorValues[index];
                            if (requestSimu?.simulateurid === 34 || requestSimu?.simulateurid === 35) {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      key={index}
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simulateurObject?.data?.inputs?.prix_acte === 0 &&
                                      (requestSimu.departementid === 36 || requestSimu.departementid === 56) ? (
                                        `Dont DMTO`
                                      ) : simulateurObject?.data?.inputs?.prix_acte === 1 &&
                                        (requestSimu.departementid === 36 || requestSimu.departementid === 56) ? (
                                        `Dont DMTO (5,09006%) servitude ${index + 1}`
                                      ) : simulateurObject?.data?.inputs?.prix_acte === 0 ? (
                                        `Dont DMTO`
                                      ) : simulateurObject?.data?.inputs?.prix_acte === 1 ? (
                                        `Dont DMTO (5,80665%) servitude ${index + 1}`
                                      ) : (
                                        <></>
                                      )}
                                    </Text>
                                  </View>

                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simulateurObject?.data?.inputs?.prix_acte === 1 ? <>{baseTresorValue}</> : <></>}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {value}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "tpf") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simuPret.includes(requestSimu.simulateurid)
                                        ? "Dont TPF (0,71498%) (base de calcul majorée des accessoires 20%)"
                                        : simuDonation.includes(requestSimu.simulateurid)
                                        ? "Dont TPF (0,61422%)"
                                        : "Dont TPF (0,71498%)"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTpfValue = null;
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_tpf") {
                                            baseTpfValue = parseInt(result[i].value);
                                          }
                                          if (result[i].key === "base_tresor") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTpfValue === null) {
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        } else {
                                          if (baseTpfValue !== null) {
                                            const formattedValue = baseTpfValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "tpf") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "droit_partage") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  {renderDroitDePartage()}

                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let basePartageValue = null;
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_droit_partage") {
                                            basePartageValue = parseInt(result[i].value);
                                          }
                                          if (result[i].key === "base_tresor") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (basePartageValue === null) {
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        } else {
                                          if (basePartageValue !== null) {
                                            const formattedValue = basePartageValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "droit_partage") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (result[i].key === "droit_echange") {
                                return (
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 0.6,
                                        paddingRight: 4,
                                        borderRight: "1px solid #eeeae5",
                                        height: 18,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          width: "100%",
                                          textAlign: "right",
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        Dont droit échange (5%)
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        height: 18,
                                        flex: 0.2,
                                        borderRight: "1px solid #eeeae5",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                          marginTop: 3.8,
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {(() => {
                                          let baseTresorValue = null;

                                          for (let i = 0; i < result.length; i++) {
                                            if (result[i].key === "base_droit_echange") {
                                              baseTresorValue = parseInt(result[i].value);
                                            }
                                          }

                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }

                                          return "";
                                        })()}
                                      </Text>
                                    </View>
                                    <Text
                                      style={{
                                        flex: 0.2,
                                        marginTop: 3.8,
                                        textAlign: "center",
                                      }}
                                    >
                                      {(() => {
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "droit_echange") {
                                            return result[i].value;
                                          }
                                        }
                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                );
                              }
                            }
                            return "";
                          })()}
                        </>
                        <>
                          {result.map((object) => {
                            if (
                              object.key === "csi_publication" &&
                              requestSimu?.simulateurid !== 18 &&
                              requestSimu?.simulateurid !== 21 &&
                              requestSimu?.simulateurid !== 34 &&
                              requestSimu?.simulateurid !== 35 &&
                              requestSimu?.simulateurid !== 81 &&
                              requestSimu?.simulateurid !== 82
                            ) {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {object.value === "15 €" ? "Dont CSI fixe" : "Dont CSI de publication (0,10%)"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseCsiPubliValue = null;
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_csi_publication") {
                                            baseCsiPubliValue = parseInt(result[i].value);
                                          }
                                          if (result[i].key === "base_tresor") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseCsiPubliValue === null) {
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        } else {
                                          if (baseCsiPubliValue !== null) {
                                            const formattedValue = baseCsiPubliValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "csi_publication") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (
                                result[i].key === "csi_publication_1" &&
                                !valeurSPF.includes(requestSimu.simulateurid) &&
                                !servitudes.includes(requestSimu.simulateurid)
                              ) {
                                return (
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 0.6,
                                        paddingRight: 4,
                                        borderRight: "1px solid #eeeae5",
                                        height: 18,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          width: "100%",
                                          textAlign: "right",
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {simulateurObject?.data?.inputs?.nombre_donateurs === 2
                                          ? "Dont CSI de publication 1er donateur (0,10%)"
                                          : requestSimu?.simulateurid === 18 || requestSimu?.simulateurid === 21
                                          ? "Dont CSI de publication sur le lot le plus élevé (0,10%)"
                                          : "Dont CSI de publication (0,10%)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        height: 18,
                                        flex: 0.2,
                                        borderRight: "1px solid #eeeae5",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                          marginTop: 3.8,
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {(() => {
                                          let baseTresorValue = null;

                                          for (let i = 0; i < result.length; i++) {
                                            if (
                                              result[i].key === "base_tresor_1" ||
                                              result[i].key === "base_csi_publication_1"
                                            ) {
                                              baseTresorValue = parseInt(result[i].value);
                                            }
                                          }

                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }

                                          return "";
                                        })()}
                                      </Text>
                                    </View>
                                    <Text
                                      style={{
                                        flex: 0.2,
                                        marginTop: 3.8,
                                        textAlign: "center",
                                      }}
                                    >
                                      {(() => {
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "csi_publication_1") {
                                            return result[i].value;
                                          }
                                        }
                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                );
                              }
                            }
                            return "";
                          })()}
                        </>
                        <>
                          {(() => {
                            for (let i = 0; i < result.length; i++) {
                              if (
                                result[i].key === "csi_publication_2" &&
                                !valeurSPF.includes(requestSimu.simulateurid) &&
                                !servitudes.includes(requestSimu.simulateurid)
                              ) {
                                return (
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 0.6,
                                        paddingRight: 4,
                                        borderRight: "1px solid #eeeae5",
                                        height: 18,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          width: "100%",
                                          textAlign: "right",
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {requestSimu?.simulateurid === 18 || requestSimu?.simulateurid === 21
                                          ? "Dont CSI de publication sur le lot le plus faible (0,10%)"
                                          : "Dont CSI de publication 2ème donateur (0,10%)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        height: 18,
                                        flex: 0.2,
                                        borderRight: "1px solid #eeeae5",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                          marginTop: 3.8,
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {(() => {
                                          let baseTresorValue = null;

                                          for (let i = 0; i < result.length; i++) {
                                            if (
                                              result[i].key === "base_tresor_2" ||
                                              result[i].key === "base_csi_publication_2"
                                            ) {
                                              baseTresorValue = parseInt(result[i].value);
                                            }
                                          }

                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }

                                          return "";
                                        })()}
                                      </Text>
                                    </View>
                                    <Text
                                      style={{
                                        flex: 0.2,
                                        marginTop: 3.8,
                                        textAlign: "center",
                                      }}
                                    >
                                      {(() => {
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "csi_publication_2") {
                                            return result[i].value;
                                          }
                                        }
                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                );
                              }
                            }
                            return "";
                          })()}
                        </>
                        <>
                          {csiPublicationValues.map((value, index) => {
                            const baseTresorValue = baseTresorValues[index];
                            if (requestSimu?.simulateurid === 34 || requestSimu?.simulateurid === 35) {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simulateurObject?.data?.inputs?.prix_acte === 0 || simulateurObject?.data?.inputs?.prix_acte === "0"
                                        ? `Dont CSI de publication (0,10%)`
                                        : `Dont CSI de publication (0,10%) servitude ${index + 1}`}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {baseTresorValue}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {value}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {csiPublicationValues.map((value, index) => {
                            const baseTresorValue = baseTresorValues[index];
                            if (requestSimu?.simulateurid === 81) {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont CSI de publication (0,10%) SPF n°
                                      {index + 1}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {baseTresorValue}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {value}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>

                        <>
                          {csiPublicationValues.map((value, index) => {
                            const baseCsiValue = baseCsiValues[index];
                            if (requestSimu?.simulateurid === 82) {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont CSI de publication (0,10%) SPF n°
                                      {index + 1}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {baseCsiValue}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {value}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>

                        <>
                          {result.map((object) => {
                            if (object.key === "droit_donation_1") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simulateurObject?.data?.inputs?.nombre_donateurs === 2
                                        ? "Dont TPF 1er donateur (0,61422%)"
                                        : "Dont TPF (0,61422%)"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_tresor_1") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTresorValue !== null) {
                                          const formattedValue = baseTresorValue
                                            .toLocaleString("en-EN")
                                            .replace(/,/g, " ");
                                          return (formattedValue + " €").toString();
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "droit_donation_1") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>

                        <>
                          {result.map((object) => {
                            if (object.key === "droit_donation_2") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont TPF 2ème donateur (0,61422%)
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_tresor_2") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTresorValue !== null) {
                                          const formattedValue = baseTresorValue
                                            .toLocaleString("en-EN")
                                            .replace(/,/g, " ");
                                          return (formattedValue + " €").toString();
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "droit_donation_2") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>

                        <>
                          {result.map((object) => {
                            if (object.key === "droit_partage_rapport") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont droits de partage (2,50%) sur le montant des rapports
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "rapport_total") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTresorValue !== null) {
                                          const formattedValue = baseTresorValue
                                            .toLocaleString("en-EN")
                                            .replace(/,/g, " ");
                                          return (formattedValue + " €").toString();
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "droit_partage_rapport") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "csi_inscription") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {simuPret.includes(requestSimu.simulateurid)
                                        ? "Dont CSI d'inscription (0,05%) (base de calcul majorée des accessoires 20%)"
                                        : "Dont CSI d'inscription (0,05%)"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseCsiInscrValue = null;
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_csi_inscription") {
                                            baseCsiInscrValue = parseInt(result[i].value);
                                          }
                                          if (result[i].key === "base_tresor") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseCsiInscrValue === null) {
                                          if (baseTresorValue !== null) {
                                            const formattedValue = baseTresorValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        } else {
                                          if (baseCsiInscrValue !== null) {
                                            const formattedValue = baseCsiInscrValue
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ");
                                            return (formattedValue + " €").toString();
                                          }
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "csi_inscription") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "csi_publication_usufruit") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont CSI de publication (0,10%) sur l'usufruit le plus élevé
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTresorValue = null;

                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "usufruit_retenu") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTresorValue !== null) {
                                          const formattedValue = baseTresorValue
                                            .toLocaleString("en-EN")
                                            .replace(/,/g, " ");
                                          return (formattedValue + " €").toString();
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "csi_publication_usufruit") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {requestSimu.simulateurid === 32 ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Dont droit d'enregistrement pour une cession de fonds de commerce
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    marginTop: 3.8,
                                    marginBottom: "auto",
                                  }}
                                >
                                  {(() => {
                                    let baseTresorValue = null;
                                    for (let i = 0; i < result.length; i++) {
                                      if (result[i].key === "base_tresor") {
                                        baseTresorValue = parseInt(result[i].value);
                                      }
                                    }

                                    if (baseTresorValue !== null) {
                                      const formattedValue = baseTresorValue.toLocaleString("en-EN").replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }

                                    return "";
                                  })()}
                                </Text>
                              </View>
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "Tresor_total") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          )}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "csi_publication_rapport") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont CSI de publication (0,10%) sur le montant des rapports (hors espèces)
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    >
                                      {(() => {
                                        let baseTresorValue = null;
                                        for (let i = 0; i < result.length; i++) {
                                          if (result[i].key === "base_csi_publication_rapport") {
                                            baseTresorValue = parseInt(result[i].value);
                                          }
                                        }

                                        if (baseTresorValue !== null) {
                                          const formattedValue = baseTresorValue
                                            .toLocaleString("en-EN")
                                            .replace(/,/g, " ");
                                          return (formattedValue + " €").toString();
                                        }

                                        return "";
                                      })()}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "csi_publication_rapport") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {result.map((object) => {
                            if (object.key === "DMTG") {
                              return (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.6,
                                      paddingRight: 4,
                                      borderRight: "1px solid #eeeae5",
                                      height: 18,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "right",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    >
                                      Dont DMTG
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      height: 18,
                                      flex: 0.2,
                                      borderRight: "1px solid #eeeae5",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginTop: 3.8,
                                        marginBottom: "auto",
                                      }}
                                    ></Text>
                                  </View>
                                  <Text
                                    style={{
                                      flex: 0.2,
                                      marginTop: 3.8,
                                      textAlign: "center",
                                    }}
                                  >
                                    {(() => {
                                      for (let i = 0; i < result.length; i++) {
                                        if (result[i].key === "DMTG") {
                                          return result[i].value;
                                        }
                                      }
                                      return "";
                                    })()}
                                  </Text>
                                </View>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </>
                        <>
                          {isDependanceValid(simu84Index) ||
                          isDependanceValid(simu85Index) ||
                          isDependanceValid(simu92Index) ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  borderTop: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    fontFamily: "RobotoMedium",
                                  }}
                                >
                                  Droits dus au trésor : acte de prêt
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                  borderTop: "1px solid #eeeae5",
                                }}
                              />
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderTop: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    marginTop: 3.8,
                                    textAlign: "center",
                                    fontFamily: "RobotoBold",
                                  }}
                                >
                                  {isDependanceValid(simu84Index)
                                    ? formattedTresorTotal84
                                    : isDependanceValid(simu85Index)
                                    ? formattedTresorTotal85
                                    : isDependanceValid(simu92Index)
                                    ? formattedTresorTotal92
                                    : null}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>
                          )}
                        </>

                        <>
                          {isDependanceValid(simu84Index) ||
                          isDependanceValid(simu85Index) ||
                          isDependanceValid(simu92Index) ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Dont TPF (0,71498%) (base de calcul majorée des accessoires 20%)
                                </Text>
                              </View>
                              {isDependanceValid(simu84Index) ||
                              isDependanceValid(simu85Index) ||
                              isDependanceValid(simu92Index) ? (
                                <View
                                  style={{
                                    height: 18,
                                    flex: 0.2,
                                    borderRight: "1px solid #eeeae5",
                                  }}
                                >
                                  <Text
                                    style={{
                                      textAlign: "center",
                                      width: "100%",
                                      marginTop: 3.8,
                                      marginBottom: "auto",
                                    }}
                                  >
                                    {isDependanceValid(simu84Index)
                                      ? formattedBaseTpf84
                                      : isDependanceValid(simu85Index)
                                      ? formattedBaseTpf85
                                      : isDependanceValid(simu92Index)
                                      ? formattedBaseTpf92
                                      : null}
                                  </Text>
                                </View>
                              ) : (
                                <></>
                              )}
                              {isDependanceValid(simu84Index) ||
                              isDependanceValid(simu85Index) ||
                              isDependanceValid(simu92Index) ? (
                                <Text
                                  style={{
                                    flex: 0.2,
                                    marginTop: 3.8,
                                    textAlign: "center",
                                  }}
                                >
                                  {isDependanceValid(simu84Index)
                                    ? formattedTpfValue84
                                    : isDependanceValid(simu85Index)
                                    ? formattedTpfValue85
                                    : isDependanceValid(simu92Index)
                                    ? formattedTpfValue92
                                    : null}
                                </Text>
                              ) : (
                                <></>
                              )}
                            </View>
                          ) : (
                            <></>
                          )}
                        </>
                        <>
                          {isDependanceValid(simu84Index) ||
                          isDependanceValid(simu85Index) ||
                          isDependanceValid(simu92Index) ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Dont CSI d’inscription (0,05%) (base de calcul majorée des accessoires 20%)
                                </Text>
                              </View>
                              {isDependanceValid(simu84Index) ||
                              isDependanceValid(simu85Index) ||
                              isDependanceValid(simu92Index) ? (
                                <View
                                  style={{
                                    height: 18,
                                    flex: 0.2,
                                    borderRight: "1px solid #eeeae5",
                                  }}
                                >
                                  <Text
                                    style={{
                                      textAlign: "center",
                                      width: "100%",
                                      marginTop: 3.8,
                                      marginBottom: "auto",
                                    }}
                                  >
                                    {isDependanceValid(simu84Index)
                                      ? formattedBaseCsiInscription84
                                      : isDependanceValid(simu85Index)
                                      ? formattedBaseCsiInscription85
                                      : isDependanceValid(simu92Index)
                                      ? formattedBaseCsiInscription92
                                      : null}
                                  </Text>
                                </View>
                              ) : (
                                <></>
                              )}
                              {isDependanceValid(simu84Index) ||
                              isDependanceValid(simu85Index) ||
                              isDependanceValid(simu92Index) ? (
                                <Text
                                  style={{
                                    flex: 0.2,
                                    marginTop: 3.8,
                                    textAlign: "center",
                                  }}
                                >
                                  {isDependanceValid(simu84Index)
                                    ? formattedCsiInscriptionValue84
                                    : isDependanceValid(simu85Index)
                                    ? formattedCsiInscriptionValue85
                                    : isDependanceValid(simu92Index)
                                    ? formattedCsiInscriptionValue92
                                    : null}
                                </Text>
                              ) : (
                                <></>
                              )}
                            </View>
                          ) : (
                            <></>
                          )}
                        </>

                        <>
                          {isDependanceValid(simu89Index) || isDependanceValid(simu96Index) ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                                marginBottom: -3,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  borderTop: "1px solid #eeeae5",
                                  height: 25,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    fontFamily: "RobotoMedium",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Droits dus au trésor : acte de cautionnement sans garantie
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 25,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                  borderTop: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    fontFamily: "RobotoBold",
                                    marginTop: 7.3,
                                  }}
                                >
                                  <></>
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 25,
                                  flex: 0.2,
                                  borderTop: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    fontFamily: "RobotoBold",
                                    marginTop: 7.3,
                                  }}
                                >
                                  0€
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>
                          )}
                        </>
                      </View>

                      <View
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #eeeae5",
                          display: "flex",
                          fontSize: 8.5,
                          flexDirection: "column",
                          gap: -5,
                        }}
                      >
                        {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 2,
                              paddingBottom: 2,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.6,
                                paddingRight: 4,
                                borderRight: "1px solid #eeeae5",
                                height: 18,
                              }}
                            >
                              <Text
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  fontFamily: "RobotoMedium",
                                }}
                              >
                                Droits dus au trésor : acte de cautionnement avec garantie
                              </Text>
                            </View>

                            <View
                              style={{
                                height: 18,
                                flex: 0.2,
                                borderRight: "1px solid #eeeae5",
                              }}
                            />

                            <Text
                              style={{
                                flex: 0.2,
                                marginTop: 3.8,
                                textAlign: "center",
                                fontFamily: "RobotoBold",
                              }}
                            >
                              {isDependanceValid(simu90Index)
                                ? formattedTresorTotal90
                                : isDependanceValid(simu97Index)
                                ? formattedTresorTotal97
                                : null}
                            </Text>
                          </View>
                        ) : (
                          <></>
                        )}

                        {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 2,
                              paddingBottom: 2,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.6,
                                paddingRight: 4,
                                borderRight: "1px solid #eeeae5",
                                height: 18,
                              }}
                            >
                              <Text
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                Dont TPF (0,71498%) (base de calcul majorée des accessoires 20%)
                              </Text>
                            </View>
                            {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    marginTop: 3.8,
                                    marginBottom: "auto",
                                  }}
                                >
                                  {isDependanceValid(simu90Index)
                                    ? formattedBaseTresor90
                                    : isDependanceValid(simu97Index)
                                    ? formattedBaseTresor97
                                    : null}
                                </Text>
                              </View>
                            ) : (
                              <></>
                            )}
                            {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {isDependanceValid(simu90Index)
                                  ? formattedTpfValue90
                                  : isDependanceValid(simu97Index)
                                  ? formattedTpfValue97
                                  : null}
                              </Text>
                            ) : (
                              <></>
                            )}
                          </View>
                        ) : (
                          <></>
                        )}

                        {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 2,
                              paddingBottom: 2,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.6,
                                paddingRight: 4,
                                borderRight: "1px solid #eeeae5",
                                height: 18,
                              }}
                            >
                              <Text
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                Dont CSI d’inscription (0,05%) (base de calcul majorée des accessoires 20%)
                              </Text>
                            </View>
                            {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    marginTop: 3.8,
                                    marginBottom: "auto",
                                  }}
                                >
                                  {isDependanceValid(simu90Index)
                                    ? formattedBaseTresor90
                                    : isDependanceValid(simu97Index)
                                    ? formattedBaseTresor97
                                    : null}
                                </Text>
                              </View>
                            ) : (
                              <></>
                            )}
                            {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {isDependanceValid(simu90Index)
                                  ? formattedCsiInscriptionValue90
                                  : isDependanceValid(simu97Index)
                                  ? formattedCsiInscriptionValue97
                                  : null}
                              </Text>
                            ) : (
                              <></>
                            )}
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </>
                  ) : (
                    <></>
                  )}
                  {result.map((object) => {
                    if (
                      ((object.key === "droit_etat_acte_separe" && object.value !== "0 €") ||
                        (object.key === "droit_etat" && object.value !== "0 €") ||
                        (object.key === "droit_etat_simplifie" && object.value !== "0 €")) &&
                      !simuDependances.includes(requestSimu?.simulateurid)
                    ) {
                      return (
                        <View
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #eeeae5",
                            display: "flex",
                            fontSize: 8.5,
                            flexDirection: "column",
                            gap: -5,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 2,
                              paddingBottom: 2,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.6,
                                paddingRight: 4,
                                borderRight: "1px solid #eeeae5",
                                height: 18,
                              }}
                            >
                              <Text
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  fontFamily: "RobotoMedium",
                                }}
                              >
                                Droits états
                              </Text>
                            </View>
                            <View
                              style={{
                                height: 18,
                                flex: 0.2,
                                borderRight: "1px solid #eeeae5",
                              }}
                            />
                            <View
                              style={{
                                height: 18,
                                flex: 0.2,
                              }}
                            />
                          </View>

                          {object.key === "droit_etat_acte_separe" || object.key === "droit_etat" ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  {simuPret.includes(requestSimu.simulateurid)
                                    ? "Droit état - acte séparé"
                                    : "Droit état"}
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "droit_etat" || result[i].key === "droit_etat_acte_separe") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          )}

                          {object.key === "droit_etat_simplifie" ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Droit état simplfié
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "droit_etat_simplifie") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>
                      );
                    } else {
                      return <></>;
                    }
                  })}

                  {/* {console.log("Contenu de simulateurObject:", simulateurObject)} */}

                  {(hasDroitEtat || hasDroitEtatPromesse || hasDroitEtatActeSepareDependances) &&
                  simuDependances.includes(requestSimu?.simulateurid) ? (
                    <View
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #eeeae5",
                        display: "flex",
                        fontSize: 8.5,
                        flexDirection: "column",
                        gap: -5,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <View
                          style={{
                            flex: 0.6,
                            paddingRight: 4,
                            borderRight: "1px solid #eeeae5",
                            height: 18,
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "auto",
                              marginBottom: "auto",
                              fontFamily: "RobotoMedium",
                            }}
                          >
                            Droits états
                          </Text>
                        </View>
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                            borderRight: "1px solid #eeeae5",
                          }}
                        />
                        <View
                          style={{
                            height: 18,
                            flex: 0.2,
                          }}
                        />
                      </View>

                      {isDependanceValid(simu84Index) ||
                      isDependanceValid(simu85Index) ||
                      isDependanceValid(simu92Index) ? (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.6,
                              paddingRight: 4,
                              borderRight: "1px solid #eeeae5",
                              height: 18,
                            }}
                          >
                            <Text
                              style={{
                                width: "100%",
                                textAlign: "right",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Droit état prêt - acte séparé
                            </Text>
                          </View>
                          <View
                            style={{
                              height: 18,
                              flex: 0.2,
                              borderRight: "1px solid #eeeae5",
                            }}
                          />
                          <Text
                            style={{
                              flex: 0.2,
                              marginTop: 3.8,
                              textAlign: "center",
                            }}
                          >
                            {isDependanceValid(simu84Index)
                              ? formattedDroitEtat84
                              : isDependanceValid(simu85Index)
                              ? formattedDroitEtat85
                              : isDependanceValid(simu92Index)
                              ? formattedDroitEtat92
                              : null}
                          </Text>
                        </View>
                      ) : (
                        <></>
                      )}

                      {isDependanceValid(simu89Index) || isDependanceValid(simu96Index) ? (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.6,
                              paddingRight: 4,
                              borderRight: "1px solid #eeeae5",
                              height: 18,
                            }}
                          >
                            <Text
                              style={{
                                width: "100%",
                                textAlign: "right",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Droit état cautionnement sans garantie - acte séparé
                            </Text>
                          </View>
                          <View
                            style={{
                              height: 18,
                              flex: 0.2,
                              borderRight: "1px solid #eeeae5",
                            }}
                          />
                          <Text
                            style={{
                              flex: 0.2,
                              marginTop: 3.8,
                              textAlign: "center",
                            }}
                          >
                            {isDependanceValid(simu89Index)
                              ? formattedDroitEtat89
                              : isDependanceValid(simu96Index)
                              ? formattedDroitEtat96
                              : null}
                          </Text>
                        </View>
                      ) : (
                        <></>
                      )}

                      {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <View
                            style={{
                              flex: 0.6,
                              paddingRight: 4,
                              borderRight: "1px solid #eeeae5",
                              height: 18,
                            }}
                          >
                            <Text
                              style={{
                                width: "100%",
                                textAlign: "right",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Droit état cautionnement avec garantie - acte séparé
                            </Text>
                          </View>
                          <View
                            style={{
                              height: 18,
                              flex: 0.2,
                              borderRight: "1px solid #eeeae5",
                            }}
                          />
                          <Text
                            style={{
                              flex: 0.2,
                              marginTop: 3.8,
                              textAlign: "center",
                            }}
                          >
                            {isDependanceValid(simu90Index)
                              ? formattedDroitEtat90
                              : isDependanceValid(simu97Index)
                              ? formattedDroitEtat97
                              : null}
                          </Text>
                        </View>
                      ) : (
                        <></>
                      )}

                      {result.map((object) => {
                        if (object.key === "droit_etat") {
                          return (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Droit état
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "droit_etat") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          );
                        } else {
                          return <></>;
                        }
                      })}

                      {result.map((object) => {
                        if (object.key === "droit_etat_simplifie") {
                          return (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Droit état simplfié
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "droit_etat_simplifie") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          );
                        } else {
                          return <></>;
                        }
                      })}

                      {result.map((object) => {
                        if (object.key === "droit_etat_promesse" && object.value !== "0 €") {
                          return (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <View
                                style={{
                                  flex: 0.6,
                                  paddingRight: 4,
                                  borderRight: "1px solid #eeeae5",
                                  height: 18,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "right",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  Droit état promesse de vente
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 18,
                                  flex: 0.2,
                                  borderRight: "1px solid #eeeae5",
                                }}
                              />
                              <Text
                                style={{
                                  flex: 0.2,
                                  marginTop: 3.8,
                                  textAlign: "center",
                                }}
                              >
                                {(() => {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i].key === "droit_etat_promesse") {
                                      const formattedValue = parseInt(result[i].value)
                                        .toLocaleString("en-EN")
                                        .replace(/,/g, " ");
                                      return (formattedValue + " €").toString();
                                    }
                                  }
                                  return "";
                                })()}
                              </Text>
                            </View>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </View>
                  ) : (
                    <></>
                  )}
                </>
              );
            } else {
              return <></>;
            }
          })}

          <View
            style={{
              width: "100%",
              display: "flex",
              fontSize: 8.5,
              flexDirection: "column",
              gap: -5,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: -3,
                marginBottom: -3,
              }}
            >
              <View
                style={{
                  flex: 0.6,
                  paddingRight: 4,
                  borderRight: "1px solid #eeeae5",
                  height: 25,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    textAlign: "right",
                    fontFamily: "RobotoBold",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  TOTAL DES DROITS DUS AU TRÉSOR PUBLIC (arrondi)
                </Text>
              </View>
              <View
                style={{
                  height: 25,
                  flex: 0.2,
                  borderRight: "1px solid #eeeae5",
                }}
              />
              <View
                style={{
                  height: 25,
                  flex: 0.2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontFamily: "RobotoBold",
                    marginTop: 7.3,
                  }}
                >
                  {(() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "Tresor_total") {
                        const numericValue = parseFloat(result[i].value);
                        const roundedValue = Math.ceil(numericValue);
                        const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      }
                    }
                    return "";
                  })()}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* END BLOC 3 */}
        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            height: 28,
            backgroundColor: "#55a0ab",
            marginTop: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            TOTAL FISCALITÉ (arrondi)
          </Text>

          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {(() => {
              const tvaValue = (() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "TVA_total") {
                    return parseInt(result[i].value) || 0;
                  }
                }
                return 0;
              })();
              const tresorValue = (() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "Tresor_total") {
                    return parseInt(result[i].value) || 0;
                  }
                }
                return 0;
              })();
              const roundedTotal = Math.ceil(tvaValue + tresorValue);
              const formattedValue = roundedTotal.toLocaleString("en-EN").replace(/,/g, " ") + " €";
              return formattedValue;
            })()}
          </Text>
        </View>

        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            marginTop: 25,
            border: "1px solid #dd9374",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              width: "100%",
              height: 28,
              backgroundColor: "#dd9374",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 10,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              DÉTAIL DÉBOURS
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              height: 24,
              borderBottom: "1px solid #f7e7df",
              backgroundColor: "#f7e7df",
              textAlign: "left",
              fontFamily: "RobotoBold",
              fontSize: 9.5,
            }}
          >
            <Text
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: 10,
              }}
            >
              DÉBOURS
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              fontSize: 8.5,
              flexDirection: "column",
              gap: -5,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: -3,
                marginBottom: -3,
              }}
            >
              {simulateurObject.data.inputs.montant_vente !== null &&
              simulateurObject.data.inputs.montant_vente !== undefined &&
              requestSimu.hasOwnProperty("dependances") ? (
                <>
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Débours acte de vente
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 25,
                      flex: 0.4,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "left",
                        marginLeft: 52,
                        marginTop: 7.3,
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "base_debours") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </>
              ) : simulateurObject?.data?.inputs?.urbanisme === 1 || simulateurObject?.data?.inputs?.urbanisme === "1" || simulateurObject?.data?.inputs?.urbanisme === 2 || simulateurObject?.data?.inputs?.urbanisme === "2" ? (
                <>
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Débours
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 25,
                      flex: 0.4,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "left",
                        marginLeft: 52,
                        marginTop: 7.3,
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "base_debours") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </>
              ) : (
                <>
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Débours
                    </Text>
                  </View>

                  <View
                    style={{
                      height: 25,
                      flex: 0.4,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "left",
                        marginLeft: 52,
                        marginTop: 7.3,
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Debours_total") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </>
              )}
            </View>

            {isDependanceValid(simu84Index) || isDependanceValid(simu85Index) || isDependanceValid(simu92Index) ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 2,
                  paddingBottom: 2,
                  marginTop: -3,
                  marginBottom: -3,
                }}
              >
                <View
                  style={{
                    flex: 0.6,
                    paddingRight: 4,
                    borderRight: "1px solid #eeeae5",
                    height: 25,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Débours acte de prêt
                  </Text>
                </View>
                <View
                  style={{
                    height: 25,
                    flex: 0.4,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: 52,
                      marginTop: 7.3,
                    }}
                  >
                    {isDependanceValid(simu84Index)
                      ? formattedDeboursValue84
                      : isDependanceValid(simu85Index)
                      ? formattedDeboursValue85
                      : isDependanceValid(simu92Index)
                      ? formattedDeboursValue92
                      : null}
                  </Text>
                </View>
              </View>
            ) : (
              <></>
            )}

            {isDependanceValid(simu89Index) || isDependanceValid(simu96Index) ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 2,
                  paddingBottom: 2,
                  marginTop: -3,
                  marginBottom: -3,
                }}
              >
                <View
                  style={{
                    flex: 0.6,
                    paddingRight: 4,
                    borderRight: "1px solid #eeeae5",
                    height: 25,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Débours acte de cautionnement sans garantie
                  </Text>
                </View>
                <View
                  style={{
                    height: 25,
                    flex: 0.4,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: 52,
                      marginTop: 7.3,
                    }}
                  >
                    {isDependanceValid(simu89Index)
                      ? formattedDeboursValue89
                      : isDependanceValid(simu96Index)
                      ? formattedDeboursValue96
                      : null}
                  </Text>
                </View>
              </View>
            ) : (
              <></>
            )}

            {isDependanceValid(simu90Index) || isDependanceValid(simu97Index) ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 2,
                  paddingBottom: 2,
                  marginTop: -3,
                  marginBottom: -3,
                }}
              >
                <View
                  style={{
                    flex: 0.6,
                    paddingRight: 4,
                    borderRight: "1px solid #eeeae5",
                    height: 25,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Débours acte de cautionnement avec garantie
                  </Text>
                </View>
                <View
                  style={{
                    height: 25,
                    flex: 0.4,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginLeft: 52,
                      marginTop: 7.3,
                    }}
                  >
                    {isDependanceValid(simu90Index)
                      ? formattedDeboursValue90
                      : isDependanceValid(simu97Index)
                      ? formattedDeboursValue97
                      : null}
                  </Text>
                </View>
              </View>
            ) : (
              <></>
            )}
          </View>

          {simulateurObject?.data?.inputs?.urbanisme === 1 || simulateurObject?.data?.inputs?.urbanisme === "1" || simulateurObject?.data?.inputs?.urbanisme === 2 || simulateurObject?.data?.inputs?.urbanisme === "2" ? (
            <View>
              <View
                style={{
                  width: "100%",
                  height: 24,
                  borderBottom: "1px solid #d8e9ec",
                  borderTop: "1px solid #d8e9ec",
                  backgroundColor: "#f7e7df",
                  textAlign: "left",
                  fontFamily: "RobotoBold",
                  fontSize: 9.5,
                }}
              >
                <Text
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10,
                  }}
                >
                  DÉBOURS SUPPLÉMENTAIRES À PRÉVOIR ET/OU DEMANDES D’URBANISME PARTICULIÈRES
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: 8.5,
                  flexDirection: "column",
                  gap: -5,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 2,
                    paddingBottom: 2,
                    marginTop: -3,
                    marginBottom: -3,
                  }}
                >
                  <View
                    style={{
                      flex: 0.6,
                      paddingRight: 4,
                      borderRight: "1px solid #eeeae5",
                      height: 25,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Débours supplémentaires à prévoir et/ou demandes d’urbanisme particulières
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 25,
                      flex: 0.4,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "left",
                        marginLeft: 52,
                        marginTop: 7.3,
                      }}
                    >
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "debours_urbanisme") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>

        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            height: 28,
            backgroundColor: "#dd9374",
            marginTop: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            TOTAL DÉBOURS
          </Text>

          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {(() => {
              for (let i = 0; i < result.length; i++) {
                if (result[i].key === "Debours_total") {
                  const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                  return (formattedValue + " €").toString();
                }
              }
              return "";
            })()}
          </Text>
        </View>

        <View
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            height: 28,
            border: "1px solid grey",
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 20,
            borderRadius: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            MONTANT TOTAL DE LA PROVISION SUR FRAIS (arrondi)
          </Text>

          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 9.5,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {(() => {
              for (let i = 0; i < result.length; i++) {
                if (result[i].key === "Provision_total") {
                  const numericValue = parseFloat(result[i].value);
                  const roundedValue = Math.ceil(numericValue);
                  const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                  return (formattedValue + " €").toString();
                }
              }
              return "";
            })()}
          </Text>
        </View>

        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
            peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
        </View>
      </Page>
      {/*  END P3  */}

      {/* restitution des DMTG */}

      {requestSimu?.DMTG?.length > 0 ? (
        <Page style={styles.page}>
          <View>
            <Text style={styles.resultsTitle}>Résultat de votre calcul de DMTG :</Text>

            <View style={styles.resultsBlock}>
              {requestSimu?.DMTG.map((array, donataire) => {
                if (simuSuccession.includes(dmtgInputs.data[0].donataires[0].simulateurid)) {
                  return (
                    <>
                      {array?.donataires.map((object, donataire) => {
                        let output = object.output;

                        const orderedKeys = [
                          "DMTG",
                          "base_dmtg",
                          "reliquat_total",
                          "immo",
                          "especes",
                          "abattement_total",
                          "abattement_restant",
                          "tranche_initiale",
                          "reliquat_tranche_donation",
                          "tranche_finale"
                        ];


                        return (
                          <>
                            <View style={styles.heritierBlock} wrap={false}>
                              <Text style={styles.heritierTitle}>Héritier {donataire + 1}</Text>
                              <View style={styles.heritierBlock2} wrap={false}>
                                <View style={styles.donateurResults}>
                                   {orderedKeys.map((line) => {
                              if (line === "DMTG" && output["DMTG"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="DMTG">
                                    Droits DMTG à payer :{" "}
                                    {parseInt(output["DMTG"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              } else if (line === "base_dmtg" && output["base_dmtg"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="base_dmtg">
                                    Base Taxable :{" "}
                                    {parseInt(output["base_dmtg"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              } else if (line === "reliquat_total" && parseInt(output["reliquat_total"]) !== 0 && output["reliquat_total"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="reliquat_total">
                                    Déduction faite du reliquat total des parts de tranches non utilisées lors des donations antérieures à 2012 :{" "}
                                    {parseInt(output["reliquat_total"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              } else if (line === "immo" && output["immo"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="immo">
                                    Montant Immobilier :{" "}
                                    {parseInt(output["immo"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              } else if (line === "especes" && output["especes"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="especes">
                                    Montant Espèces :{" "}
                                    {parseInt(output["especes"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              }  else if (line === "abattement_total") {
                                return (
                                    <Text style={styles.dmtgResults}>
                                      Abattement appliqué :{" "}
                                      {parseInt(output["abattement_total"])
                                          .toLocaleString("en-EN")
                                          .replace(/,/g, " ") + " €"}
                                    </Text>
                                );
                              }
                              else if (line === "abattement_restant" && output["abattement_restant"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults} key="abattement_restant">
                                    Abattement légal restant :{" "}
                                    {parseInt(output["abattement_restant"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              }else if (line === "tranche_initiale" && output["tranche_initiale"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults}>
                                  Taux tranche initiale :{" "}
                                  {(output["tranche_initiale"] * 100).toLocaleString("en-EN") + " %"}
                                </Text>
                                );
                              }
                              else if (line === "reliquat_tranche_donation" && output["reliquat_tranche_donation"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults}>
                                  Reliquat de tranche initiale non utilisé lors des donations antérieures :{" "}
                                  {parseInt(output["reliquat_tranche_donation"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                  </Text>
                                );
                              }
                              else if (line === "tranche_finale" && output["tranche_finale"] !== undefined) {
                                return (
                                  <Text style={styles.dmtgResults}>
                                  Taux tranche finale :{" "}
                                  {(output["tranche_finale"] * 100).toLocaleString("en-EN") + " %"}
                                </Text>
                                );
                              }

                              return null; // Si aucune condition n'est remplie
                            })}
                                </View>
                              </View>
                            </View>
                          </>
                        );
                      })}
                    </>
                  );
                } else {
                  return (
                    <>
                      <View style={styles.globalBlock}>
                        <View style={styles.donataireTitle}>
                          <Text>Donateur {donataire + 1}</Text>
                        </View>
                        <View style={styles.donataireBlock}>
                          {array?.donataires.map((object, donateur) => {
                            let output = object.output;
                            return (
                              <>
                                <View style={styles.donateurBlock} wrap={false}>
                                  <Text style={styles.donateurTitle}>Donataire {donateur + 1}</Text>
                                  <View style={styles.donateurResults}>
                                    {Object.keys(output).map((line, index) => {
                                      if (line === "DMTG") {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Droits DMTG à payer :{" "}
                                            {parseInt(output["DMTG"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                          </Text>
                                        );
                                      } else if (line === "base_dmtg") {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Base Taxable :{" "}
                                            {parseInt(output["base_dmtg"]).toLocaleString("en-EN").replace(/,/g, " ") +
                                              " €"}
                                          </Text>
                                        );
                                      } else if (line === "reliquat_total" && parseInt(output["reliquat_total"]) !== 0) {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Déduction faite du reliquat total des parts de tranches non utilisées lors des donations antérieures à 2012 :{" "}
                                            {parseInt(output["reliquat_total"])
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ") + " €"}
                                          </Text>
                                        );
                                      } else if (line === "immo") {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Montant Immobilier :{" "}
                                            {parseInt(output["immo"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                          </Text>
                                        );
                                      } else if (line === "especes") {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Montant Espèces :{" "}
                                            {parseInt(output["especes"]).toLocaleString("en-EN").replace(/,/g, " ") +
                                              " €"}
                                          </Text>
                                        );
                                      } else if (line === "abattement_total") {
                                        return (
                                            <Text style={styles.dmtgResults}>
                                              Abattement appliqué :{" "}
                                              {parseInt(output["abattement_total"])
                                                  .toLocaleString("en-EN")
                                                  .replace(/,/g, " ") + " €"}
                                            </Text>
                                        );
                                      } else if (line === "abattement_restant") {
                                        return (
                                          <Text style={styles.dmtgResults}>
                                            Abattement légal restant :{" "}
                                            {parseInt(output["abattement_restant"])
                                              .toLocaleString("en-EN")
                                              .replace(/,/g, " ") + " €"}
                                          </Text>
                                        );
                                      }
                                        else if (line === "reliquat_tranche_donation" && output["reliquat_tranche_donation"] !== undefined) {
                                          return (
                                            <Text style={styles.dmtgResults}>
                                            Reliquat de tranche initiale non utilisé lors des donations antérieures :{" "}
                                            {parseInt(output["reliquat_tranche_donation"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                            </Text>
                                          );
                                        } else if (line === "tranche_finale") {
                                        return (
                                          <>
                                            <Text style={styles.dmtgResults}>
                                              Taux tranche initiale :{" "}
                                              {(output["tranche_initiale"] * 100).toLocaleString("en-EN") + " %"}
                                            </Text>
                                            <Text style={styles.dmtgResults}>
                                              Taux tranche finale :{" "}
                                              {(output["tranche_finale"] * 100).toLocaleString("en-EN") + " %"}
                                            </Text>
                                          </>
                                        );
                                      }
                                      return <></>;
                                    })}
                                  </View>
                                </View>
                              </>
                            );
                          })}
                        </View>
                      </View>
                    </>
                  );
                }
              })}
            </View>

            <View style={styles.rappelDMTG}>
              <Text style={styles.bodyFooterMappedTitle}>Rappel de votre calcul de DMTG :</Text>

              {dmtgInputs?.data?.map((array, donateur) => {
                if (simuSuccession.includes(dmtgInputs.data[0].donataires[0].simulateurid)) {
                  return (
                    <>
                      {dmtgInputs?.data[donateur]?.donataires?.map((inputs, donataire) => {
                        return (
                          // DMTG successions
                          <>
                            <View style={styles.rappelBlockDonataire} wrap={false}>
                              <Text style={styles.rappelDonataireTitle}>Héritier {donataire + 1}</Text>
                              {Object.keys(dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                if (key === "lien_parente_donation") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre époux ou partenaires pacsés
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : En ligne directe - Ascendants ou enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre frères et sœurs
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Aux neveux et nièces
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>Lien de parenté : Aux petits-enfants</Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Aux arrières petits-enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre parents au-dela du 4ème degré et non parents
                                      </Text>
                                    );
                                  }
                                } else if (key === "lien_parente_succession") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : En ligne directe - Ascendants ou enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                    if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                        "conditions_particulieres"
                                      ] === 1
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Entre frères et sœurs Conditions particulières : Oui
                                        </Text>
                                      );
                                    } else if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                        "conditions_particulieres"
                                      ] === 0
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Entre frères et sœurs Conditions particulières : Non
                                        </Text>
                                      );
                                    }
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                    if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Aux neveux et nièces De leur propre-chef : Oui
                                        </Text>
                                      );
                                    } else if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Aux neveux et nièces De leur propre-chef : Non
                                        </Text>
                                      );
                                    }
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre collatéraux jusqu'au 4ème degré inclusivement
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre parents au-delà du 4ème degré et non parents
                                      </Text>
                                    );
                                  }
                                } else if (key === "handicap") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Bénéficiaire en situation de handicap : Oui
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Bénéficiaire en situation de handicap : Non
                                      </Text>
                                    );
                                  }
                                } else if (key === "date_nouvelle_donation") {
                                  const date_nouvelle_donation =
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key];
                                  const date = moment(date_nouvelle_donation).format("DD/MM/YYYY");
                                  return (
                                    <>
                                      <Text style={styles.bodyFooterMapped} key={key}>
                                        Date du décès : {date}
                                      </Text>
                                    </>
                                  );
                                } else if (key === "total_succession") {
                                  const total_succession =
                                  dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["total_succession"];
                                  const montant_rapportable =
                                  dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_rapportable"];
                                  const montantSuccession =
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_succession"];
                                    const montantRevenantHeritier =
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montantRevenantHeritier"];
                                  return (
                                    <>
                                    <Text style={styles.bodyFooterMapped}>
                                      Masse à partager avec l'ensemble des héritiers :{" "}
                                      {total_succession} €
                                    </Text>
                                    <Text style={styles.bodyFooterMapped}>
                                    Dont revenant à l'héritier :{" "}
                                      {montantRevenantHeritier} €
                                    </Text>
                                    <Text style={styles.bodyFooterMapped}>
                                    Sous déduction du montant de son rapport :{" "}
                                    {montant_rapportable} €
                                  </Text>
                                  <Text style={styles.bodyFooterMapped} key={key}>
                                      Montant taxable revenant à l'héritier : {montantSuccession} €
                                      </Text>
                                  </>
                                  );
                                } else if (key === "montant_utilise") {
                                  return (
                                    <Text style={styles.bodyFooterMapped}>
                                      Abattement légal déjà utilisé :{" "}
                                      {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                    </Text>
                                  );
                                }
                                return <></>;
                              })}

                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures &&
                              dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.some(
                                (obj) => Object.keys(obj).length > 0
                              )
                                ? dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.map(
                                    (donation, index) => {
                                      if (Object.keys(donation).length === 0) return null;
                                      const date = moment(donation.date).format("DD/MM/YYYY");
                                      const montant = donation.montant.toString() + " €";
                                      return (
                                        <View key={index}>
                                          <Text style={styles.bodyFooterMapped}>
                                            {`Donation antérieure ${index + 1} - Date : ${date}`}
                                          </Text>
                                          <Text style={styles.bodyFooterMapped}>
                                            {`Donation antérieure ${index + 1} - Montant : ${montant}`}
                                          </Text>
                                        </View>
                                      );
                                    }
                                  )
                                : null}
                            </View>
                          </>
                        );
                      })}
                    </>
                  );
                } else {
                  return (
                    // DMTG donations
                    <>
                      <Text style={styles.rappelDonateurTitle} wrap={false}>
                        Donateur {donateur + 1}
                      </Text>
                      {dmtgInputs?.data[donateur]?.donataires?.map((inputs, donataire) => {
                        return (
                          <>
                            <View style={styles.rappelBlockDonataire} wrap={false}>
                              <Text style={styles.rappelDonataireTitle}>Donataire {donataire + 1}</Text>
                              {Object.keys(dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                if (key === "lien_parente_donation") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre époux ou partenaires pacsés
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : En ligne directe - Ascendants ou enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre frères et sœurs
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Aux neveux et nièces
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>Lien de parenté : Aux petits-enfants</Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Aux arrières petits-enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre parents au-dela du 4ème degré et non parents
                                      </Text>
                                    );
                                  }
                                } else if (key === "lien_parente_succession") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : En ligne directe - Ascendants ou enfants
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                    if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                        "conditions_particulieres"
                                      ] === 1
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Entre frères et sœurs Conditions particulières : Oui
                                        </Text>
                                      );
                                    } else if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                        "conditions_particulieres"
                                      ] === 0
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Entre frères et sœurs Conditions particulières : Non
                                        </Text>
                                      );
                                    }
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                    if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Aux neveux et nièces De leur propre-chef : Oui
                                        </Text>
                                      );
                                    } else if (
                                      dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0
                                    ) {
                                      return (
                                        <Text style={styles.bodyFooterMapped}>
                                          Lien de parenté : Aux neveux et nièces De leur propre-chef : Non
                                        </Text>
                                      );
                                    }
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre collatéraux jusqu'au 4ème degré inclusivement
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Lien de parenté : Entre parents au-delà du 4ème degré et non parents
                                      </Text>
                                    );
                                  }
                                } else if (key === "tepa") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <>
                                        <Text style={styles.bodyFooterMapped}>
                                          Abattement spécifique loi TEPA : Oui
                                        </Text>
                                        <Text style={styles.bodyFooterMapped}>
                                          Abattement TEPA déjà utilisé :{" "}
                                          {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                            "montant_tepa_utilise"
                                          ].toString() + " €"}
                                        </Text>
                                      </>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>Abattement spécifique loi TEPA : Non</Text>
                                    );
                                  }
                                } else if (key === "montant_utilise") {
                                  return (
                                    <Text style={styles.bodyFooterMapped}>
                                      Abattement légal déjà utilisé :{" "}
                                      {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                    </Text>
                                  );
                                } else if (key === "handicap") {
                                  if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Bénéficiaire en situation de handicap : Oui
                                      </Text>
                                    );
                                  } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                    return (
                                      <Text style={styles.bodyFooterMapped}>
                                        Bénéficiaire en situation de handicap : Non
                                      </Text>
                                    );
                                  }
                                } else if (key === "montant_especes") {
                                  return (
                                    <Text style={styles.bodyFooterMapped}>
                                      Montant espèces :{" "}
                                      {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                    </Text>
                                  );
                                } else if (key === "montant_immo") {
                                  return (
                                    <Text style={styles.bodyFooterMapped}>
                                      Montant immobilier :{" "}
                                      {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                    </Text>
                                  );
                                } else if (key === "date_nouvelle_donation") {
                                  const date_nouvelle_donation =
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key];
                                  const date = moment(date_nouvelle_donation).format("DD/MM/YYYY");
                                  const montantDonation =
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_donation"];

                                  return (
                                    <>
                                      <Text style={styles.bodyFooterMapped} key={key}>
                                        Nouvelle donation - Date : {date}
                                      </Text>
                                      <Text style={styles.bodyFooterMapped} key={key}>
                                        Nouvelle donation - Montant : {montantDonation} €
                                      </Text>
                                    </>
                                  );
                                }

                                return <></>;
                              })}

                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures &&
                              dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.some(
                                (obj) => Object.keys(obj).length > 0
                              )
                                ? dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.map(
                                    (donation, index) => {
                                      if (Object.keys(donation).length === 0) return null;
                                      const date = moment(donation.date).format("DD/MM/YYYY");
                                      const montant = donation.montant.toString() + " €";
                                      return (
                                        <View key={index}>
                                          <Text style={styles.bodyFooterMapped}>
                                            {`Donation antérieure ${index + 1} - Date : ${date}`}
                                          </Text>
                                          <Text style={styles.bodyFooterMapped}>
                                            {`Donation antérieure ${index + 1} - Montant : ${montant}`}
                                          </Text>
                                        </View>
                                      );
                                    }
                                  )
                                : null}
                            </View>
                          </>
                        );
                      })}
                    </>
                  );
                }
              })}
            </View>
          </View>

          <View style={styles.bodyFooterCtn} fixed>
            <Text style={styles.bodyFooterMedium}>
              IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
              peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
              adressés par virement au minimum 48h-72h à l'avance.
            </Text>
            <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
          </View>
        </Page>
      ) : (
        <></>
      )}
      {/* END restitution des DMTG */}
    </Document>
  );
};
export default GeneratePdfFile;
