import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import "./essentiel.css";
import useIsMountedRef from "../../utils/mountedRef";

const alphabet = "ABCDEILMNPQRTV".split("");

export default function MENU_FISCA({ setActiveFiscaItem, setSelectedFiscaItems, currentTabFromURL }) {
  const [menuItems, setMenuItems] = useState([]);
  const [activeFiscaLetter, setActiveFiscaLetter] = useState("A");
  const [activeFiscaItemLocal, setActiveFiscaItemLocal] = useState(null);
  const menuRef = useRef(null);
  const alphabetRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const jwt = user?.jwt;

        const response = await axios.get(`${API_BASE_URL}/essentiel_fiscalite`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
        });

        const data = response.data;
        if (Array.isArray(data)) {
          setMenuItems(data);

          if (currentTabFromURL.length > 0) {
            handleMenuItemClick(data[currentTabFromURL[1]], data);
            return;
          }
          if (!activeFiscaItemLocal && data.length > 0) {
            setActiveFiscaItemLocal(data[0]);
            setSelectedFiscaItems([data[0]]);
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location.href = "/login";
          }
          if (error.response.data.error == "Never connected" || error.response.data.error == "Unauthorized") {
            localStorage.removeItem("user");
            window.location.href = "/login";
          }
        }
      }
    };

    fetchData();
  }, [isMountedRef, currentTabFromURL]);

  const uniqueMenuItems = Array.from(new Set(menuItems.map((item) => item.n_acte))).map((n_acte) => {
    return menuItems.find((item) => item.n_acte === n_acte);
  });

  const handleMenuItemClick = (item, datamenu) => {
    setActiveFiscaItemLocal(item);
    setActiveFiscaItem(item);

    // Mettre à jour la lettre active
    setActiveFiscaLetter(item.letter);

    // Faire défiler la colonne des lettres pour centrer la lettre correspondante
    const letterIndex = alphabet.indexOf(item.letter);
    if (letterIndex !== -1) {
      const alphabetContainer = alphabetRef.current;
      const letterElement = alphabetContainer.children[letterIndex];
      if (letterElement) {
        const letterTopPosition = letterElement.offsetTop;
        alphabetContainer.scrollTo({
          top: letterTopPosition - alphabetContainer.clientHeight / 2,
          behavior: "smooth",
        });
      }
    }

    if (menuItems.length > 0) {
      const filteredItems = menuItems.filter((menuItem) => menuItem.n_acte === item.n_acte);
      setSelectedFiscaItems(filteredItems);
    } else {
      const filteredItems = datamenu.filter((menuItem) => menuItem.n_acte === item.n_acte);
      setSelectedFiscaItems(filteredItems);
    }
  };

  const handleScroll = () => {
    const menuElements = document.querySelectorAll(".menu-item");

    const marginTopOffset = 100; // Compense la marge en haut
    const letterElements = document.querySelectorAll(".group-letter h2"); // Toutes les lettres en gros
    let letterHeightOffset = 0; // Hauteur de la lettre en gros

    // Récupérer la position de défilement et la hauteur totale du menu
    const scrollPosition = menuRef.current.scrollTop;
    const maxScroll = menuRef.current.scrollHeight - menuRef.current.clientHeight;

    // Si on arrive en bas du menu, forcer la sélection de la dernière lettre
    if (scrollPosition >= maxScroll - 10) {
      const lastItem = menuElements[menuElements.length - 1];
      const lastLetter = lastItem.getAttribute("data-letter");
      setActiveFiscaLetter(lastLetter);

      const lastLetterIndex = alphabet.indexOf(lastLetter);
      if (lastLetterIndex !== -1) {
        const alphabetContainer = alphabetRef.current;
        const letterElement = alphabetContainer.children[lastLetterIndex];
        if (letterElement) {
          alphabetContainer.scrollTo({
            top: alphabetContainer.scrollHeight - alphabetContainer.clientHeight,
            behavior: "smooth",
          });
        }
      }
      return;
    }

    // Logique normale pour les autres items
    for (let i = 0; i < menuElements.length; i++) {
      const element = menuElements[i];
      const boundingRect = element.getBoundingClientRect();

      // Si une lettre en gros est présente avant cet item, ajuster le décalage pour cette lettre
      const prevLetterElement = letterElements[i - 1];
      if (prevLetterElement) {
        letterHeightOffset = prevLetterElement.offsetHeight;
      }

      if (boundingRect.top >= marginTopOffset + letterHeightOffset && boundingRect.top < window.innerHeight / 2) {
        const letter = element.getAttribute("data-letter");
        setActiveFiscaLetter(letter);

        const letterIndex = alphabet.indexOf(letter);
        if (letterIndex !== -1) {
          const alphabetContainer = alphabetRef.current;
          const letterElement = alphabetContainer.children[letterIndex];
          if (letterElement) {
            const letterTopPosition = letterElement.offsetTop;
            alphabetContainer.scrollTo({
              top: letterTopPosition - alphabetContainer.clientHeight / 2,
              behavior: "smooth",
            });
          }
        }
        break;
      }
    }
  };

  useEffect(() => {
    const menuElement = menuRef.current;
    if (menuElement) {
      menuElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [menuItems]);

  useEffect(() => {
    handleScrollToLetter("A");
  }, []);

  const handleScrollToLetter = (letter) => {
    const element = document.querySelector(`.menu-item[data-letter='${letter}']`);
    if (element) {
      const menuTop = menuRef.current.offsetTop;

      // Récupère la hauteur de la grosse lettre (h2), si elle existe
      const letterElement = document.querySelector(`.group-letter h2`);
      const letterHeight = letterElement ? letterElement.offsetHeight : 0;

      // Ajuster la position avec une marge supplémentaire pour éviter que la mauvaise lettre soit sélectionnée
      const marginTopOffset = 10 + letterHeight; // Ajuster cette valeur si nécessaire
      const elementTopPosition = element.offsetTop - menuTop - marginTopOffset;

      menuRef.current.scrollTo({
        top: elementTopPosition,
        behavior: "smooth",
      });
      setActiveFiscaLetter(letter);
    }
  };

  // fonction permettant le scroll sur l'acte en fonction de l'index récupéré dans l'url
  useEffect(() => {
    if (menuItems.length > 0) {
      const scrollToActeFromURL = () => {
        const hashParts = window.location.hash.split("#");
        if (hashParts.length > 2) {
          const rowIndex = parseInt(hashParts[2], 10);

          const targetElement = document.querySelector(`.menu-item[data-row-index="${rowIndex}"]`);

          if (targetElement) {
            const menuTop = menuRef.current.offsetTop;
            const elementTop = targetElement.offsetTop;

            menuRef.current.scrollTo({
              top: elementTop - menuTop - 20,
              behavior: "smooth",
            });

            const targetItem = menuItems[rowIndex];
            if (targetItem) {
              setActiveFiscaItemLocal(targetItem);
              setActiveFiscaItem([targetItem]);
            }
          }
        }
      };

      scrollToActeFromURL();
    }
  }, [menuItems]);

  return (
    <div className="sidebar-container">
      <div className="alphabet-column" ref={alphabetRef}>
        {alphabet.map((letter) => (
          <React.Fragment key={letter}>
            <div
              className={`alphabet-letter ${activeFiscaLetter === letter ? "active" : ""}`}
              onClick={() => handleScrollToLetter(letter)}
            >
              {letter}
            </div>
            {letter !== alphabet[alphabet.length - 1] && <div className="letter-separator"></div>}
          </React.Fragment>
        ))}
      </div>
      <div className="menu-column" ref={menuRef}>
        {uniqueMenuItems.length > 0 ? (
          uniqueMenuItems.map((item, index) => {
            const prevItem = uniqueMenuItems[index - 1];
            const showLetter = index === 0 || item.letter !== prevItem.letter; // Afficher la lettre si elle change
            const rowIndex = menuItems.findIndex((menuItem) => menuItem === item);

            return (
              <React.Fragment key={item.n_acte}>
                {showLetter && (
                  <div className="group-letter">
                    <h2
                      style={{
                        borderTop: index > 0 && item.letter !== prevItem.letter ? "3px solid #016954" : "",
                      }}
                    >
                      {item.letter}
                    </h2>
                  </div>
                )}
                <div
                  className={`menu-item ${activeFiscaItemLocal?.n_acte === item.n_acte ? "active" : ""} `}
                  onClick={() => handleMenuItemClick(item)}
                  data-letter={item.letter}
                  data-row-index={rowIndex}
                  data-n-acte={item.n_acte}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.actes_mef }} />
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
