import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./essentiel.css";
import ReactGA from "react-ga4";
import { useHistory, useLocation } from "react-router-dom";


const ONGLETS = ({ activeTab, setActiveTab }) => {
  const history = useHistory();
  const location = useLocation();

  const tabs = [
    { name: "ÉMOLUMENTS D’ACTES", className: "link-actes" },
    { name: "ÉMOLUMENTS DE FORMALITÉS", className: "link-form" },
    { name: "Fiscalité", className: "link-fiscalite" },
    { name: "Droits fixes (rappel)", className: "link-fixes" },
    { name: "Débours", className: "link-fiches" },
    { name: "Fiches pratiques", className: "link-fiches" },
  ];

  const handleClick = () => {
    const baseUrl = "/" + location.pathname.split("/")[1]; 
    history.push(baseUrl);
  };

  return (
    <nav className="onglets_essentiel">
      <ul className="ul_essentiel">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className={`li_essentiel ${tab.className} ${activeTab === tab.name ? "active" : "inactive"}`}
            onClick={() => {
              ReactGA.event({
                category: "Essentiel",
                action: "Clic sur onglet",
                label: "essentielonglet " + tab.name,
              });
              handleClick();              
              setActiveTab(tab.name);
            }}
          >
            <Link>{tab.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ONGLETS;
